import api from '../api';
import router from '../router';

export default {
    state: { accountsCompanyId: null },
    mutations: {
        accountsCompanyId(state, data) {
            state.accountsCompanyId = data;
        },
    },
    actions: {
        async runGLTrialBalance(context, { accountsCompanyId, financialYear, financialPeriod }) {
            // REMOVE FINANCIAL PERIODS!!!!!!!!
            try {
                context.commit('accountsCompanyId', accountsCompanyId, financialYear, financialPeriod);
                const result = await api.post(context, 'report/gltrialbalance', {
                    accountsCompanyId,
                    financialYear,
                    financialPeriod,
                });
                return result.data;
            } catch (e) {
                throw e;
            }
        },
    },
    getters: {
        accountsCompanyId(state) {
            return state.accountsCompanyId;
        },
    },
};
