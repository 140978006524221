import Vue from 'vue';
import Router from 'vue-router';
import Auth from './components/app/Auth';
import Empty from './components/app/Empty';
import store from '@/store';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/about',
            name: 'about',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('@/views/auth/Login'),
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('loggedin')) {
                    next('/auth/dashboard');
                } else next();
            },
        },
        {
            path: '/signup',
            name: 'SignUp',
            component: () => import('@/views/auth/SignUp'),
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('loggedin')) {
                    next('/auth/dashboard');
                } else next();
            },
        },
        {
            path: '/badbrowser',
            name: 'BadBrowser',
            component: () => import('@/views/auth/BadBrowser'),
        },
        {
            path: '/terms',
            name: 'Terms',
            component: () => import('@/views/admin/info/Terms'),
        },
        {
            path: '/privacy',
            name: 'Privacy',
            component: () => import('@/views/admin/info/Privacy'),
        },
        {
            path: '/auth',
            component: Auth,
            beforeEnter: async (to, from, next) => {
                // https://stackoverflow.com/questions/46262094/checking-auth-token-valid-before-route-enter-in-vue-router

                try {
                    if (store.state.auth.sid) {
                        console.log('auth is fine');
                        next();
                    } else {
                        throw new Error('redirect to login');
                    }
                } catch (e) {
                    next(`/login?redirectTo=${to.fullPath}`);
                }

                next();
            },
            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: () => import('@/views/Dashboard.vue'),
                },
                {
                    path: 'actionflow',
                    name: 'ActionFlow',
                    component: () => import('@/views/tools/ActionFlow'),
                },
                {
                    path: 'dataloader',
                    name: 'DataLoader',
                    component: () => import('@/views/tools/DataLoader'),
                },
                {
                    path: 'loginspector',
                    name: 'LogInspector',
                    component: () => import('@/views/tools/logInspector/LogInspector'),
                },
                {
                    path: 'webhook',
                    name: 'Webhook',
                    component: () => import('@/views/tools/Webhook'),
                },
                {
                    path: 'tefileupload',
                    name: 'tefileupload',
                    component: () => import('@/views/tools/TEFileUpload'),
                },
                {
                    path: 'bill',
                    component: Empty,
                    children: [
                        {
                            path: 'paymentrun',
                            name: 'BillPaymentRun',
                            component: () => import('@/views/accounts/bill/paymentRun/PaymentRun'),
                        },
                        {
                            path: 'paymentrun/:folderCode',
                            name: 'BillPaymentRunTab',
                            component: () => import('@/views/accounts/bill/paymentRun/PaymentRun'),
                        },
                        {
                            path: 'releasepayments',
                            name: 'ReleasePayments',
                            component: () => import('@/views/accounts/bill/releasePayments/ReleasePayments'),
                        },
                        {
                            path: 'approve/lookup/:accountsId',
                            name: 'BillLookup',
                            component: () => import('@/views/accounts/bill/approve/Lookup'),
                        },
                        {
                            path: 'approve',
                            name: 'BillApprove',
                            component: () => import('@/views/accounts/bill/approve/Approve'),
                        },
                        {
                            path: 'billprofile',
                            name: 'BillProfileList',
                            component: () => import('@/views/accounts/bill/billProfile/BillProfileList'),
                        },
                        {
                            path: 'billprofile/:id',
                            name: 'BillProfileDetail',
                            component: () => import('@/views/accounts/bill/billProfile/BillProfileDetail'),
                        },
                    ],
                },
                {
                    path: 'property',
                    component: Empty,
                    children: [
                        {
                            path: 'rentrun',
                            name: 'RentRun',
                            component: () => import('@/views/property/RentRun'),
                        },
                    ],
                },
                {
                    path: 'test1',
                    name: 'Test1',
                    component: () => import('@/views/junk/Brian'),
                },
                {
                    path: 'testareas',
                    name: 'TestAreas',
                    component: () => import('@/views/junk/testAreas/TestAreas.vue'),
                },
                {
                    path: 'companyapdelete',
                    name: 'CompanyAPDelete',
                    component: () => import('@/views/admin/quickbooks/CompanyAPDelete'),
                },
                {
                    path: 'document',
                    name: 'Document',
                    component: () => import('@/views/accounts/document/DocumentList'),
                },
                {
                    path: 'report',
                    component: Empty,
                    children: [
                        {
                            path: 'junk',
                            name: 'JunkReport',
                            component: () => import('@/views/junk/Report'),
                        },
                        {
                            path: 'gltrialbalance',
                            name: 'GLTrialBalance',
                            component: () => import('@/views/reports/GLTrialBalance.vue'),
                        },
                    ],
                },
                {
                    path: 'admin',
                    component: Empty,
                    children: [
                        {
                            path: 'company',
                            name: 'CompanyList',
                            component: () => import('@/views/admin/company/CompanyList.vue'),
                        },
                        {
                            path: 'company/sync/:id',
                            name: 'CompanySync',
                            component: () => import('@/views/admin/company/CompanySync.vue'),
                        },
                        {
                            path: 'company/:id',
                            name: 'CompanyDetail',
                            component: () => import('@/views/admin/maintenance/CompanyDetail.vue'),
                        },
                        {
                            path: 'connectqb',
                            name: 'ConnectQB',
                            component: () => import('@/views/admin/quickbooks/ConnectQB'),
                        },
                        {
                            path: 'completeqb',
                            name: 'CompleteQB',
                            component: () => import('@/views/admin/quickbooks/CompleteQB'),
                        },
                        {
                            path: 'connectgoogle',
                            name: 'ConnectGoogle',
                            component: () => import('@/views/admin/google/ConnectGoogle'),
                        },
                        {
                            path: 'completegoogle',
                            name: 'CompleteGoogle',
                            component: () => import('@/views/admin/google/CompleteGoogle'),
                        },
                        {
                            path: 'config',
                            name: 'Config',
                            component: () => import('@/views/admin/Config'),
                        },
                        {
                            path: 'extractexcel',
                            name: 'ExtractExcel',
                            component: () => import('@/views/tools/ExtractExcel'),
                        },

                        {
                            path: 'account',
                            name: 'AccountList',
                            component: () => import('@/views/admin/maintenance/AccountList'),
                        },
                        {
                            path: 'account/:id',
                            name: 'AccountDetail',
                            component: () => import('@/views/admin/maintenance/AccountDetail'),
                        },

                        {
                            path: 'bank',
                            name: 'bankList',
                            component: () => import('@/views/admin/maintenance/BankList'),
                        },
                        {
                            path: 'bank/:id',
                            name: 'BankDetail',
                            component: () => import('@/views/admin/maintenance/BankDetail'),
                        },
                        {
                            path: 'bill/:source/:id',
                            name: 'BillDetail',
                            component: () => import('@/views/admin/maintenance/BillDetail'),
                        },
                        {
                            path: 'billdefinition',
                            name: 'BillDefinitionList',
                            component: () => import('@/views/admin/maintenance/bill/billDefinition/List'),
                        },
                        {
                            path: 'billdefinition/:id',
                            name: 'BillDefinitionDetail',
                            component: () => import('@/views/admin/maintenance/bill/billDefinition/Detail'),
                        },
                        {
                            path: 'billimport',
                            name: 'BillImportList',
                            component: () => import('@/views/admin/maintenance/bill/billImport/List'),
                        },
                        {
                            path: 'billimport/:id',
                            name: 'BillImportDetail',
                            component: () => import('@/views/admin/maintenance/bill/billImport/Detail'),
                        },
                        {
                            path: 'costcentre',
                            name: 'CostCentreList',
                            component: () => import('@/views/admin/maintenance/CostCentreList'),
                        },
                        {
                            path: 'costcentre/:id',
                            name: 'CostCentreDetail',
                            component: () => import('@/views/admin/maintenance/CostCentreDetail'),
                        },
                        {
                            path: 'customer',
                            name: 'CustomerList',
                            component: () => import('@/views/admin/maintenance/CustomerList'),
                        },
                        {
                            path: 'customer/:id',
                            name: 'CustomerDetail',
                            component: () => import('@/views/admin/maintenance/CustomerDetail'),
                        },
                        {
                            path: 'item',
                            name: 'ItemList',
                            component: () => import('@/views/admin/maintenance/ItemList'),
                        },
                        {
                            path: 'item/:id',
                            name: 'ItemDetail',
                            component: () => import('@/views/admin/maintenance/ItemDetail'),
                        },
                        {
                            path: 'lease',
                            name: 'LeaseList',
                            component: () => import('@/views/property/lease/LeaseList'),
                        },
                        {
                            path: 'lease/:id',
                            name: 'LeaseDetail',
                            component: () => import('@/views/property/lease/LeaseDetail'),
                        },
                        {
                            path: 'lease/:id/leasearea',
                            name: 'LeaseArea',
                            component: () => import('@/views/property/lease/LeaseArea'),
                        },
                        {
                            path: 'property',
                            name: 'PropertyList',
                            component: () => import('@/views/admin/maintenance/PropertyList'),
                        },
                        {
                            path: 'property/:id',
                            name: 'PropertyDetail',
                            component: () => import('@/views/admin/maintenance/PropertyDetail'),
                        },
                        {
                            path: 'property/:propertyId/propertyarea/:id',
                            name: 'PropertyAreaDetail',
                            component: () => import('@/views/admin/maintenance/PropertyAreaDetail'),
                        },
                        {
                            path: 'vendor',
                            name: 'VendorList',
                            component: () => import('@/views/admin/maintenance/VendorList'),
                        },
                        {
                            path: 'vendor/:id',
                            name: 'VendorDetail',
                            component: () => import('@/views/admin/maintenance/VendorDetail'),
                        },
                        {
                            path: 'user',
                            name: 'UserList',
                            component: () => import('@/views/admin/maintenance/UserList'),
                        },
                        {
                            path: 'user/:id',
                            name: 'UserDetail',
                            component: () => import('@/views/admin/maintenance/UserDetail'),
                        },
                        {
                            path: 'acpgroup',
                            name: 'AcpGroupList',
                            component: () => import('@/views/admin/acp/AcpGroupList'),
                        },
                        {
                            path: 'acpgroup/:id',
                            name: 'AcpGroupDetail',
                            component: () => import('@/views/admin/acp/AcpGroupDetail'),
                        },
                        {
                            path: 'unpaidbill',
                            name: 'UnpaidBillList',
                            component: () => import('@/views/admin/enquiries/UnpaidBillList'),
                        },
                    ],
                },
                {
                    path: 'storageking',
                    component: Empty,
                    children: [
                        {
                            path: 'project',
                            name: 'ProjectList',
                            component: () => import('@/views/storageKing/ProjectList'),
                        },
                        {
                            path: 'project/:id',
                            name: 'ProjectDetail',
                            component: () => import('@/views/storageKing/ProjectDetail'),
                        },
                    ],
                },
            ],
        },
        {
            path: '/render',
            component: Empty,
            beforeEnter: async (to, from, next) => {
                // https://stackoverflow.com/questions/46262094/checking-auth-token-valid-before-route-enter-in-vue-router

                try {
                    if (to.query && to.query.sessionid) {
                        await store.dispatch('setStorageData', {
                            sid: to.query.sessionid,
                        });
                    }

                    if (store.state.auth.sid) {
                        next();
                    } else {
                        throw new Error('redirect to login');
                    }
                } catch (e) {
                    next(`/login?redirectTo=${to.fullPath}`);
                }

                next();
            },
            children: [
                {
                    path: 'report/:id',
                    name: 'RenderReport',
                    component: () => import('@/views/reports/Render'),
                },
            ],
        },
        {
            path: '',
            name: 'Default',
            redirect: '/auth/dashboard',
        },
    ],
});
