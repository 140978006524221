<template>
    <fragment>
        <read-only-field v-if="readOnly" :value="getTextValue" v-bind="$attrs" />
        <v-combobox v-else :label="label" v-model="localValue" v-bind="$attrs" v-on="$listeners" />
        <debug value="combobox on vuetify is fucky. may be fixed in future. do not use for now" />
    </fragment>
</template>

<script>
import cleanUtil from '@/../common/utils/clean';

export default {
    props: {
        value: [Array, String],
        label: String,
        readOnly: Boolean,
    },
    data() {
        return {
            localValue: [],
        };
    },
    methods: {
        init() {
            console.log('comboboxfield ', this.value, this.readOnly, this.$attrs);
            this.localValue = cleanUtil.validate.isArray(this.value) ? this.value : [];
        },
    },
    computed: {
        getTextValue() {
            return this.localValue.join(', ').substring(0, this.localValue.length - 2);
        },
    },
    updated() {
        // this.init();
    },
    mounted() {
        this.init();
    },
};
</script>
