<template>
    <v-radio-group v-bind="$attrs" v-model="localValue" class="px-2 py-0 ma-0" :key="`${JSON.stringify(localValue)}`" :row="row">
        <template v-slot:label>
            <div class="text-caption">{{ label }}</div>
        </template>
        <v-radio
            v-for="(item, idx) in items"
            :key="`${idx}`"
            :label="item.text || item"
            :value="item.value || item"
        />
    </v-radio-group>
</template>

<script>
export default {
    props: {
        value: null,
        items: null,
        label: null,
        row: Boolean,
    },
    data() {
        return {
            localValue: '',
            localKey: '',
        };
    },
    updated() {
        this.localValue = this.value;
        this.localKey = JSON.stringify({ v: this.value, i: this.items });
    },
    mounted() {
        this.localValue = this.value;
        this.localKey = JSON.stringify({ v: this.value, i: this.items });
    },
    watch: {
        localValue() {
            if (this.localValue) this.$emit('input', this.localValue);
        },
    },
};
</script>
