<template>
    <div>
        <Menu class="no-print" :drawer="drawer" :key="sid" style="z-index: 50" />
        <router-view @change="setMenu"></router-view>
    </div>
</template>
<script>
import Menu from '@/components/app/Menu';

export default {
    components: { Menu },
    data() {
        return {
            drawer: true,
            sid: null,
            userId: null,
        };
    },
    methods: {
        setMenu(sid) {
            this.sid = sid;
        },
    },
    mounted() {
        const user = this.$store.getters.user;
        if (user) {
            const { id } = user;
            this.userId = id;
        }
    },
};
</script>
