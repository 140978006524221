<template>
    <v-dialog v-model="showDialog" width="500" height="300" align-top>
        <v-card v-if="isShowAddNote">
            <v-textarea class="pb-0" v-model="newNoteText" label="Enter note" />
            <v-container class="pb-1 pt-0">
                <toolbar
                    v-if="isNewNote"
                    :left-items="[
                        { id: 'save', onClick: saveNote },
                        { id: 'done', onClick: closeDialog },
                    ]"
                />
                <toolbar
                    v-else
                    :left-items="[
                        { id: 'save', onClick: saveNote },
                        { id: 'delete', onClick: deleteNote },
                        { id: 'cancel', onClick: closeDialog },
                    ]"
                />
            </v-container>
        </v-card>
        <v-card v-else>
            <smart-table
                :headers="headers"
                :items="noteData"
                dense
                class="elevation-0"
                :itemsPerPage="5"
                itemKey="noteId"
                :key="JSON.stringify(noteData)"
                @click:row="noteSelected"
                :single-select="true"
            />
            <toolbar
                :left-items="[
                    { id: 'add', onClick: showAdd },
                    { id: 'done', onClick: closeDialog },
                ]"
            />
        </v-card>
    </v-dialog>
</template>

<script>
import dateUtil from '@/../common/utils/date';
import api from '@/api';

export default {
    name: 'NoteFieldDialog',
    props: {
        showDialog: Boolean,
        newNote: Boolean,
        showAddNote: Boolean,
        noteData: Array,
    },
    data: () => ({
        lines: [],
        headers: [
            {
                text: 'User',
                align: 'left',
                sortable: true,
                value: 'user.name',
            },
            {
                text: 'Date',
                align: 'center',
                sortable: false,
                value: 'date',
            },
            {
                text: 'Note',
                align: 'center',
                sortable: false,
                value: 'text',
            },
        ],
        isShowAddNote: false,
        isNewNote: false,
        newNoteText: '',
        noteId: undefined,
    }),
    methods: {
        closeDialog() {
            this.$emit('closeNoteDialog');
        },
        async saveNote() {
            const date = dateUtil.getTodayString();
            await api.post(this.$store, `note`, { date, text: this.newNoteText, id: this.noteId });
            this.newNoteText = '';
            this.noteId = undefined;
            this.$emit('changedNotes');
            this.closeDialog();
        },
        showAdd() {
            this.isNewNote = true;
            this.isShowAddNote = true;
            this.$emit('showAddNote');
        },
        noteSelected(item) {
            this.newNoteText = item.text;
            this.noteId = item.id;
            this.isShowAddNote = true;
            this.isNewNote = false;
            this.$emit('showAddNote');
        },
        async deleteNote() {
            await api.delete(this.$store, `note/${this.noteId}`);
            this.newNoteText = '';
            this.noteId = undefined;
            this.$emit('changedNotes');
        },
    },
    watch: {
        newNote(value) {
            this.isNewNote = value;
        },
        showAddNote(value) {
            this.isShowAddNote = value;
        },
    },
};
</script>

<style scoped></style>
