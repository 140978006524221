<template>
    <div v-bind="$attrs">
        <a :href="fileUrl" target="_blank" :download="filename">
            <strong v-bind="$attrs">{{ filename }}</strong></a
        >
<!--        <a @click="fileDownload(true)">-->
<!--            <strong v-bind="$attrs">{{ filename }}</strong>-->
<!--        </a>-->
        <btn
            v-bind="$attrs"
            @click="fileDownload(false)"
            small
            text
            target="_blank"
            icon
            no-outlined
            class="mb-2 mx-0"
        >
            <v-progress-circular indeterminate :size="18" :width="2" v-if="isDownloading" />
            <v-icon v-else>cloud_download</v-icon>
        </btn>
    </div>
</template>
<script>
import axios from 'axios';
import fileDownload from 'js-file-download';

import { loadFilesBySource, loadFile } from '@/views/storageKing/components/common';

export default {
    name: 'FileDownload',
    props: {
        label: String,
        collectionName: String,
        collectionId: String,
        fileType: String,
        compact: Boolean,
        fileId: String,
    },
    data() {
        return {
            imageUrl: '',
            imageFile: '',
            filename: '',
            fileUrl: '',
            mimeType: '',
            isCompact: false,
            isDownloading: false,
        };
    },
    methods: {
        async getDownloadFileUrl() {
            // let file = null;
            // let downloadUrl = null;
            let result;
            if (this.fileId) {
                result = await loadFile(this.$store, this.fileId, true);
            } else {
                result = (await loadFilesBySource(
                    this.$store,
                    this.collectionName,
                    this.collectionId,
                    this.fileType,
                    true,
                ))[0];
            }
            const { file, downloadUrl } = result;

            if (file) {
                this.filename = file.metadata.filename;
                this.mimeType = file.metadata.mimeType;
                this.fileUrl = downloadUrl;
            }
        },
        fileDownload(isOpenFile) {
            this.isDownloading = true;
            axios
                .get(this.fileUrl, {
                    responseType: 'blob',
                })
                .then(res => {
                    if (isOpenFile) {
                        const url = URL.createObjectURL(res.data);


                        const a = document.createElement('a');
                        document.body.appendChild(a);
                        a.style = 'display: none';
                        a.href = url;
                        a.download = this.filename;
                        a.target = '_blank';
                        a.click();
                    } else {
                        fileDownload(res.data, this.filename, this.mimeType);
                    }
                    this.isDownloading = false;
                });
        },
    },
    mounted() {
        this.isCompact = this.compact;
        this.getDownloadFileUrl();
    },
};
</script>
<style scoped>
#xxxdeletethispreview {
    display: flex;
    justify-content: center;
    align-items: center;
}

#xxxpreviewdeletethis img {
    max-width: 100%;
    max-height: 500px;
}
</style>
