import Vue from 'vue';
import Fragment from 'vue-fragment';
import VueMask from 'v-mask';
import VueCookies from 'vue-cookies';

import vuetify from '@/plugins/vuetify';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

import Debug from '@/components/common/Debug';
import Page from '@/components/app/Page';
import ReportPage from '@/components/common/ReportPage';

import Btn from '@/components/form/buttons/Btn';
import Toolbar from '@/components/form/buttons/Toolbar';
import Confirm from '@/components/form/buttons/Confirm';
import RunReport from '@/components/form/buttons/RunReport';

import NotificationBadge from '@/components/common/NotificationBadge';
import PageFooter from '@/components/common/PageFooter';

import AddressField from '@/components/form/fields/AddressField';
import BlankField from '@/components/form/fields/BlankField';
import CheckBoxField from '@/components/form/fields/CheckBoxField';
import ColorField from '@/components/form/fields/ColorField';
import ComboBoxField from '@/components/form/fields/ComboBoxField';
import CurrencyField from '@/components/form/fields/CurrencyField';
import DateField from '@/components/form/fields/DateField';
import FileDownloadField from '@/components/form/fields/FileDownloadField';
import FileUploadField from '@/components/form/fields/fileUpload/FileUploadField';
import MultiTextField from '@/components/form/fields/MultiTextField';
import NoteField from '@/components/form/fields/NoteField';
import RadioField from '@/components/form/fields/RadioField';
import ReadOnlyField from '@/components/form/fields/ReadOnlyField';
import SelectField from '@/components/form/fields/SelectField';
import SwitchField from '@/components/form/fields/SwitchField';
import TextField from '@/components/form/fields/TextField';

import SmartForm from '@/components/form/SmartForm';
import SmartTable from '@/components/table/SmartTable';

import browserDetect from 'vue-browser-detect-plugin';

Vue.config.productionTip = false;
Vue.use(Fragment.Plugin);
Vue.use(browserDetect);

Vue.use(VueMask);
Vue.use(VueCookies, { expire: '100d' });

Vue.component('Debug', Debug);
Vue.component('Page', Page);
Vue.component('ReportPage', ReportPage);

Vue.component('Btn', Btn);
Vue.component('Toolbar', Toolbar);
Vue.component('Confirm', Confirm);
Vue.component('RunReport', RunReport);

Vue.component('NotificationBadge', NotificationBadge);
Vue.component('PageFooter', PageFooter);

Vue.component('AddressField', AddressField);
Vue.component('BlankField', BlankField);
Vue.component('CheckBoxField', CheckBoxField);
Vue.component('ColorField', ColorField);
Vue.component('ComboBoxField', ComboBoxField);
Vue.component('CurrencyField', CurrencyField);
Vue.component('DateField', DateField);
Vue.component('FileDownloadField', FileDownloadField);
Vue.component('FileUploadField', FileUploadField);
Vue.component('MultiTextField', MultiTextField);
Vue.component('NoteField', NoteField);
Vue.component('RadioField', RadioField);
Vue.component('ReadOnlyField', ReadOnlyField);
Vue.component('SelectField', SelectField);
Vue.component('SwitchField', SwitchField);
Vue.component('TextField', TextField);

Vue.component('SmartForm', SmartForm);
Vue.component('SmartTable', SmartTable);

const vm = new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
