<template>
    <read-only-field v-if="readOnly" v-bind="$attrs" v-on="$listeners" :value="displayValue" />
    <v-autocomplete
        v-else
        :label="label"
        v-model="localValue"
        :search-input.sync="searchValue"
        :items="items"
        :hide-no-data="hideNoData"
        :return-object="returnObject"
        item-text="text"
        item-value="id"
        v-bind="$attrs"
        :clearable="false"
        @XXXclick="(val) => doOnGeneric('click', 'onClick', val)"
        @change="(val) => doOnGeneric('change', 'onChange', val)"
        @focus="(val) => doOnGeneric('focus', 'onFocus', val)"
        @blur="(val) => doOnGeneric('blur', 'onBlur', val)"
        @XXXinput="(val) => doOnGeneric('input', 'onInput', val)"
        @XXXkeyup="(val) => doOnGeneric('keyup', 'onKeyUp', val)"
        v-on="$listeners"
        @update:search-input="(val) => doOnGeneric('searchinput', 'onSearchInput', val)"
        @update:list-index="(val) => doOnGeneric('listindex', 'onListIndex', val)"
    />
</template>

<script>
import api from '@/api';

import searchUtil from '@/../common/utils/search';

export default {
    props: {
        value: null,
        label: null,
        autocomplete: null,
        returnObject: null,
        hideNoData: Boolean,
        allItemsText: String,
        readOnly: Boolean,
        mask: [String, Function],
        maskOptions: null,
        getFormData: null,
        formData: null,
    },
    data() {
        return {
            localValue: null,
            localText: '',
            items: [],
            searchValue: null,
            useMask: null,
            keyUpValue: '',
        };
    },
    methods: {
        matchValueToItems(newValue) {
            const findMatching = (val) => {
                if (!val) return this.defaultValue;

                console.log('11111111111');
                const foundItem = this.items.find((oneItem) => {
                    if (!oneItem) return false;
                    // console.log('val', this.$attrs.xxx, val);
                    return oneItem.text.match(searchUtil.getSearchString(val.text ? val.text : val, {}));
                });

                if (!foundItem) return this.defaultValue;

                return foundItem;
            };

            // console.log('values for findmatch', this.$attrs.xxx, {
            //     newValue,
            //     value: this.value,
            //     returnObject: this.returnObject,
            // });
            const foundValue = findMatching(newValue);

            // console.log('foundValue', this.$attrs.xxx, foundValue);

            if (!foundValue) {
                this.$emit('input', this.defaultValue);
            }
            return foundValue;
        },
        checkValueInList() {
            const foundItem = this.items.find((oneItem) => {
                if (!oneItem) return false;
                if (!!this.returnObject) {
                    if (this.value === null) return false;
                    return this.value.id === oneItem.id;
                }
                return this.value === oneItem.id;
            });

            if (!foundItem && !this.localValue && !!this.value) {
                this.$emit('input', this.defaultValue);
                this.$emit('change', this.defaultValue);
            }

            return foundItem;
        },
        async initialiseValue() {
            // get valid items from server
            const result = await api.post(this.$store, 'form/autocomplete', {
                autocomplete: this.autocomplete,
                value: this.defaultValue,
                formData: this.formData,
            });
            this.items = result.data;

            if (!this.value) return this.defaultValue;

            return this.checkValueInList();
        },
        doOnGeneric(event, attrEvent, val) {
            if (event === 'keyup') {
                this.keyUpValue = this.searchValue;
            }

            if (event === 'blur') {
                //console.log('doOnGeneric blurrrr1', this.$attrs.xxx, this.localValue, this.keyUpValue);
                if (this.keyUpValue) {
                    const searchItem = this.matchValueToItems(this.keyUpValue, false);

                    //console.log('doOnGeneric blurrrr2', searchItem);
                    if (searchItem) {
                        this.searchValue = searchItem.text;
                        this.localValue = this.returnObject ? searchItem : searchItem.id;
                        //console.log('matched and now vals', this.$attrs.xxx, {
                        //     value: this.value,
                        //     localValue: this.localValue,
                        //     searchValue: this.searchValue,
                        // });
                        this.$emit('input', this.localValue);
                        this.$emit('change', this.localValue);
                    }
                }
            }
        },
        async init() {
            this.localValue = await this.initialiseValue(this.value);
            this.useMask = this.maskOptions ? this.runMask(this.maskOptions) : this.mask;
        },
    },
    computed: {
        displayValue() {
            return this.returnObject && this.value ? this.value.text : this.value;
        },
        defaultValue() {
            return this.returnObject ? null : '';
        },
    },
    async updated() {
        console.log('updated autocomplete ===== REMOVE COMMENT OF THE NEXT LINE');
        this.checkValueInList();
    },
    mounted() {
        console.log('mounted autocomplete');
        this.init();
    },
};
</script>
