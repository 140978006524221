import api from '@/api';

const getPropIds = (props) => {
    if (!props) return {};

    const idKeys = Object.keys(props).filter((onePropKey) => {
        // console.log({ onePropKey });
        return onePropKey === 'id' || onePropKey.substring(onePropKey.length - 2) === 'Id';
    });

    const result = idKeys.map((oneKey) => props[oneKey]);
    // const result = idKeys.reduce((accum,oneKey) => ({ [`${oneKey}`]: props[oneKey] , ...accum}),{});

    // console.log('2', { result });

    return result;
};

const getPropStack = (context, componentTag) => {
    try {
        if (context && context.$options._componentTag === componentTag) {
            const result = [{ [`${context.$options._componentTag}`]: context.$props }];
            return [...result, ...getPropStack(context.$parent, componentTag)];
        } else if (context.$options._componentTag === 'Page') {
            const result = [{ [`${context.$options._componentTag}`]: getPropIds(context.$props) }];
            return result;
        } else {
            const result = context.$options._componentTag
                ? [{ [`${context.$options._componentTag}`]: getPropIds(context.$props) }]
                : [];
            return [...result, ...getPropStack(context.$parent, componentTag)];
        }
    } catch (e) {
        console.log(e);
    }
    //
    // if (that.$parent) return [...result, ...this.getPropStack(that.$parent)];
    //
    // return result;
};
const getLogPayload = (context, componentTag) => {
    if (context) {
        const propStack = getPropStack(context, componentTag);
        const route = {
            fullPath: context.$route.fullPath,
            params: context.$route.params,
            query: context.$route.query,
        };
        return {
            operation: 'click',
            data: {
                propStack,
                route,
            },
        };
    }
    return null;

};

//        console.log('context ',this.$vnode.context.$route.fullPath, this.$vnode.componentInstance.$props.label,  this.$vnode.componentInstance.$props.preset,this.$options.name,  this.$vnode.componentInstance.$props.iconCode )
//const logPayload = this.getLogPayload();
//console.log({ logPayload });
// console.log('context ', this.$parent, this.$options._componentTag);
//
// const props = { id: 'asdgfhjk', propertyId: 'anotherid', notone: 'skip this' };
//
// getPropIds(props);

export default async (context, componentTag) => {
    try {
        if (context.$store && context.$store.getters.isAuthenticated) {
            const payload = getLogPayload(context, componentTag);
            await api.post(context.$store, `log`, payload);
        }
    } catch (e) {
        console.log(e);
    }
};
