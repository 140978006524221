<template>
    <v-container :class="hideBorder || micro ? 'formbasenoborder' : 'formbase'">
        <v-row v-if="title" class="px-3 subtitle-1">
            {{ title }}
        </v-row>
        <v-row>
            <v-col cols="10">
                <span style="white-space: pre-wrap">{{ previewText }}</span>
            </v-col>
            <v-col cols="auto">
                <v-row class="pb-1">
                    <btn small fab preset="all" :icon="true" color="primary" @click="showAll" />
                    <v-badge color="error" overlap :content="items.length || '0'" />
                </v-row>
                <v-row class="pb-1">
                    <btn small fab preset="add" :icon="true" @click="showAdd" />
                </v-row>
            </v-col>
        </v-row>
        <NoteFieldDialog
            :showDialog="isShowDialog"
            :showAddNote="isShowAddNote"
            :newNote="isNewNote"
            :noteData="items"
            :key="JSON.stringify(items)"
            @closeNoteDialog="closeDialog"
            @showAddNote="isShowAddNote = true"
            @changedNotes="loadListData"
        />
    </v-container>
</template>

<script>
import api from '@/api';
import NoteFieldDialog from './NoteFieldDialog';

export default {
    name: 'NoteField',
    components: { NoteFieldDialog },
    data: () => ({
        title: 'Notes',
        previewText: '',
        hideBorder: false,
        newNoteText: '',
        isNewNote: false,
        micro: false,
        items: [],
        isShowDialog: false,
        isShowAddNote: false,
    }),
    methods: {
        closeDialog() {
            this.isShowDialog = false;
            this.isShowAddNote = false;
        },
        showAll() {
            this.isShowAddNote = false;
            this.isShowDialog = true;
        },
        showAdd() {
            this.isNewNote = true;
            this.isShowAddNote = true;
            this.isShowDialog = true;
        },
        async loadListData() {
            try {
                const returnData = await api.get(this.$store, `notes/60d15b6bf082ff5d6e2d5bc2`);
                this.items = returnData.data.notes;
                this.previewText = this.items.reduce((accum, oneItem, idx) => {
                    if (this.items.length - idx === 3)
                        return `${oneItem.useruserCode}: ${oneItem.text}`;
                    if (this.items.length - idx < 3) {
                        return `${accum}\n${oneItem.useruserCode}: ${oneItem.text}`;
                    }
                    return accum;
                }, '');
            } catch (e) {
                console.log('error loading note list', e.message);
            }
        },
    },
    async mounted() {
        await this.loadListData();
    },
};
</script>
.formbase { border: 1px solid #80808050; border-radius: 8px; max-width: 1100px; padding: 12px; }
.formbasenoborder { max-width: 1100px; }
<style scoped></style>
