import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import app from './app';
import auth from './auth';
import qb from './qb';
import google from './google';
import test from './test';
import log from './log';
import report from './report';
import form from './form';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        auth,
        qb,
        google,
        test,
        log,
        report,
        form,
    },
    plugins: [new VuexPersistence().plugin],
});
