import Vue from 'vue';
import Vuetify from 'vuetify';
/// import 'vuetify/src/styles/main.sass'
// import "vuetify/src/stylus/app.styl";
// import "vuetify/src/styles/main.sass";
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify, {
    iconfont: 'mdi',
    theme: { dark: true },
});

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1976D2',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            },
            dark: {
                primary: '#1976D2',
                secondary: '#BBB',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            },
        },
    },
});
