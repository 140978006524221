import escapeStringRegexp from 'escape-string-regexp';

export function modifyString(
    inValue,
    { useCode, upperCase, lowerCase, alphaOnly, numericOnly, alphaNumericOnly, allowCharacters = '', maxLength },
) {
    // in the template -
    //        {{ 'ABCabc123~-_=' | myMask }}

    // in the top of the script -
    //      import { runMask } from '@/utils/mask';
    //
    //      const myMask = runMask({ upperCase: true });

    // in the filters
    // filters: {
    //     myMask(value) {
    //         return myMask(value)[0];
    //     },
    // },
    let workingValue = `${inValue}`;

    // if (somethingSpecial) return runSomethingSpecial(inValue);

    if (useCode || upperCase) workingValue = workingValue.toUpperCase();
    if (lowerCase) workingValue = workingValue.toLowerCase();

    if (maxLength) workingValue = workingValue.substring(0, maxLength);

    let regexString;

    if (alphaOnly) regexString = `[^a-z${allowCharacters}]`;
    if (numericOnly) regexString = `[^0-9${allowCharacters}]`;
    if (useCode || alphaNumericOnly) regexString = `[^0-9a-z${allowCharacters}]`;

    if (regexString) {
        const regExp = new RegExp(`${regexString}`, 'ig');
        workingValue = workingValue.replace(regExp, '');
    }
    return workingValue;
}

export function validateString(
    inValue,
    {
        useCode = false,
        upperCase = false,
        lowerCase = false,
        alphaOnly = false,
        numericOnly = false,
        alphaNumericOnly = false,
        allowCharacters = '',
        maxLength = 999,
    },
) {
    return (
        inValue ===
        modifyString(inValue, {
            useCode,
            upperCase,
            lowerCase,
            alphaOnly,
            numericOnly,
            alphaNumericOnly,
            allowCharacters,
            maxLength,
        })
    );
}

export function runMask({
    useCode,
    upperCase,
    lowerCase,
    alphaOnly,
    numericOnly,
    alphaNumericOnly,
    allowCharacters,
    maxLength,
}) {
    return (inValue) => {
        return [
            modifyString(inValue, {
                useCode,
                upperCase,
                lowerCase,
                alphaOnly,
                numericOnly,
                alphaNumericOnly,
                allowCharacters,
                maxLength,
            }),
        ];
    };
}
