export default {
    state: { formData: null },
    mutations: {
        storeFormData(state, formData) {
            state.formData = formData;
        },
    },
    actions: {
        async getFormData(context, formCode) {
            try {
                const formData = context.getters.formData || {};
                return formData[formCode];
            } catch (e) {
                throw e;
            }
        },
        async setFormData(context, { formCode, formValue }) {
            try {
                const formData = context.getters.formData || {};
                context.commit('storeFormData', { ...formData, [formCode]: formValue});
            } catch (e) {
                throw e;
            }
        },
    },
    getters: {
        formData(state) {
            return state.formData;
        },
    },
};
