import escapeStringRegexp from 'escape-string-regexp';

const getSearchString = (
    searchString= '',
    { begins = false, fullMatch= false, caseSensitive= false, global= false }
) => {
    let escapedString = escapeStringRegexp(searchString);
    let options = '';

    if (begins || fullMatch) escapedString = `^${escapedString}`;

    if (fullMatch) escapedString = `${escapedString}$`;

    if (!caseSensitive) options = `i${options}`;
    if (global) options = `g${options}`;

    return new RegExp(escapedString, options);
};

function replaceAll(inputString, findString, replaceString, options = {}) {
    return inputString.replace(getSearchString(findString, { global: true, ...options }), replaceString);
}

export default { getSearchString, replaceAll };
