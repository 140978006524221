<template>
    <fragment>
<!--        xx{{ localValue }}ww{{ JSON.stringify(items)}}ZZ-->
    <read-only-field v-if="readOnly" :value="getTextValue" v-bind="$attrs" />
    <v-select
        v-else-if="returnObject !== null && items && items.length"
        :items="items"
        :label="label"
        v-model="localValue"
        style="min-width: min-content;"
        :dense="dense"
        :return-object="returnObject"
        item-text="text"
        item-value="id"
        v-bind="$attrs"
        :key="JSON.stringify(items)"
        @click:clear="clearValue"
    />
<!--    <debug :value="localValue" />-->
    </fragment>
</template>

<script>
export default {
    props: {
        value: null,
        items: Array,
        label: String,
        dense: Boolean,
        readOnly: Boolean,
        returnObject: { type: Boolean, default: true },
    },
    data() {
        return {
            localValue: '',
            useItems: null,
        };
    },
    methods: {
        clearValue() {
            this.$emit('input', null);
        },
    },
    updated() {
        console.log('src/components/form/fields/SelectField.vue updated 1', JSON.stringify({ value: this.value}))
        this.localValue = this.value;
    },
    watch: {
        localValue() {
            if (this.localValue) {
                console.log('form updated select-field', this.localValue)
                this.$emit('input', this.localValue);
                this.$emit('change', this.localValue);
            }
        },
    },
    computed: {
        getTextValue() {
            const itemValue = this.items.find(oneItem => oneItem === this.value);
            if (typeof itemValue !== 'undefined') return itemValue;

            const itemObject = this.items.find(oneItem => oneItem.id === this.value);
            return itemObject ? itemObject.text : 'no value';
        },
    },
    mounted() {
        console.log('src/components/form/fields/SelectField.vue mounted 1', JSON.stringify({ value: this.value}))
        this.localValue = this.value;

        try {
            const isItemLinkFormat = !!(this.items[0].id && this.items[0].text);

            if (isItemLinkFormat) {
                this.useItems = [...this.items];
            } else {
                this.useItems = this.items.map(oneItem => ({ id: oneItem, text: oneItem }));
            }
        } catch (e) {
            //do nothing
        }
    },
};
</script>

<style>
div.v-select__selections input {
    display: none;
}
div.v-menu__content {
    z-index: 500 !important;
    color: #f00;
}
</style>
