<template>
    <div v-if="filename || true" class="d-flex" tile>
        <file-download
            :collection-name="collectionName"
            :collection-id="collectionId"
            :file-type="fileType"
            :file-id="fileId"
        />
        <btn
            v-if="allowDelete"
            @click="deleteFile()"
            preset="delete"
            target="_blank"
            no-outlined

            class="mb-2 mx-0"
        />
        <Confirm ref="confirm"></Confirm>
    </div>
</template>
<script>
import Confirm from '@/components/form/buttons/Confirm';
import FileDownload from '@/components/form/fields/FileDownloadField';

import objectUtil from '@/../common/utils/object';

export default {
    name: 'FileUpload',
    components: { Confirm, FileDownload },
    props: {
        collectionName: String,
        collectionId: String,
        fileType: String,
        fileObj: Object,
        allowDelete: Boolean,
    },
    data() {
        return {
            selectedFile: null,
        };
    },
    methods: {
        async deleteFile() {
            if (this.fileId) {
                if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
                    this.$emit('delete-file', this.fileId);
                }
            }
        },
    },
    computed: {
        filename() {
            return objectUtil.getValueByKeyDefault(this.fileObj, 'file.metadata.filename');
        },
        fileId() {
            return objectUtil.getValueByKeyDefault(this.fileObj, 'file.id');
        }
    }
};
</script>
