<template>
    <v-color-picker
        v-if="localValue"
        :value="localValue"
        @input="onInput"
        hide-canvas
        hide-slidersx
        hide-inputsx
        show-swatches
        swatches-max-height="300px"
        v-bind="$attrs"
    />
</template>
<script>
export default {
    props: {
        value: String,
        label: String,
        readOnly: Boolean,
    },
    data() {
        return {
            localValue: '',
        };
    },
    methods: {
        init() {
            console.log('colorfield init', this.value, this.readOnly, this.$attrs);
            this.localValue = this.value;
            // this.localValue = '#00FF00FF';
        },
        onInput(e) {
            console.log('colorfield oninput1', e, this.value, this.localValue);
            console.log('colorfield oninput2 emitting', e);
            this.localValue = e;
            this.$emit('input', e);
            this.$emit('change', e);
        },
    },
    computed: {
        xxx() {
            return '';
        },
    },
    updated() {
        console.log('colorfield updated', this.value, this.localValue);
        // this.$emit('input', this.localValue);

        // this.init();
    },
    mounted() {
        console.log('colorfield init', this.value);
        this.init();
    },
};
</script>
