import axios from 'axios';

import objectUtil from '@/../common/utils/object';

import { apiUri } from '../constants';
import cleanUtil from '@/../common/utils/clean';

const defaultHeaders = {
    'Content-Type': 'application/json',
};

const config = (sid) => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...defaultHeaders,
    };
    if (sid) headers.sessionid = sid;
    return {
        headers,
        credentials: 'include',
    };
};

const getSidFromContext = (context) => {
    if (context && context.rootGetters && context.rootGetters.sid && !(context.rootGetters.sid instanceof Object)) return context.rootGetters.sid;

    if (context && context.getters && context.getters.sid && !(context.getters.sid instanceof Object)) return context.getters.sid;

    return false;
};

const apiTimeout = () => {
    return new Promise((resolve) => {
        setTimeout(() => resolve({ success: false, error: 'API Timed out (90 seconds)' }), 90000);
    });
};

const wrapApiCall = (context, call, rproOptions = {}) => {
    const isShowApiErrors = rproOptions.isShowErrors === undefined ? true : rproOptions.isShowErrors;
    try {
        const result = Promise.race([
            new Promise((res, rej) => {
                call()
                    .then((result) => {
                        // console.log('api wrapApiCall result', { result });
                        if (result.data && result.data.success === false && result.data.errorMessage) {
                            context.dispatch('setError', result.data.errorMessage);
                        }
                        return res(result);
                    })
                    .catch((err) => {
                        if (isShowApiErrors) {
                            if (!err.response) {
                                console.log('api err', err)
                                context.dispatch('setError', 'Error: Network Error');
                            } else {
                                if (err.response.statusText) {
                                    context.dispatch('setError', `Error ${err.response.status}: ${err.response.statusText}`);
                                }
                                if (err.response.data.message) {
                                    context.dispatch('setError', err.response.statusText);
                                }
                            }
                            res({ data: null });
                        } else rej(err);
                    });
            }),
            apiTimeout(),
        ]);

        return result;
        // todo: should we return this instead - requires review of all api calls
        //         return {
        //             ...result.data,
        //             getData(mapping) {
        //                 return objectUtil.getObjectFieldByString(mapping, this);
        //             }
        //         }
    } catch (e) {
        console.log(e);
        throw e;
    }
};

const get = (context, path, axiosOptions = {}, rproOptions = {}) => wrapApiCall(context, () => axios.get(apiUri(path), { ...config(getSidFromContext(context)), ...axiosOptions }), rproOptions);

const deleteFunc = (context, path, axiosOptions = {}, rproOptions = {}) => wrapApiCall(context, () => axios.delete(apiUri(path), { ...config(getSidFromContext(context)), ...axiosOptions }), rproOptions);

const post = (context, path, data, axiosOptions = {}, rproOptions = {}) =>
    wrapApiCall(
        context,
        () =>
            axios.post(apiUri(path), data, {
                ...config(getSidFromContext(context)),
                ...axiosOptions,
            }),
        rproOptions,
    );

const put = (context, path, data, headers, axiosOptions = {}, rproOptions = {}) =>
    wrapApiCall(
        context,
        () =>
            axios.put(apiUri(path), data, {
                ...config(getSidFromContext(context)),
                ...axiosOptions,
                headers,
            }),
        rproOptions,
    );

const setUrl = (context, url) => (url.includes('?') ? `${apiUri(url)}&sid=${getSidFromContext(context)}` : `${apiUri(url)}?sid=${getSidFromContext(context)}`);

const formDataToObject = (formData) => {
    if (formData) {
        const formKeys = Object.keys(formData);
        console.log('formDataToObject keys', formKeys)
        const formDataObject = formKeys.reduce((accum, oneFormKey, counter) => {
            const returnValue = objectUtil.setObjectFieldByString(oneFormKey, { ...accum }, formData[oneFormKey]);
            return returnValue;
        }, {});
        return formDataObject;
    }
};

const objectToFormData = (schema, dataObject) => {
    const formSchemas = getFormSchemas(schema);
    const formData = formSchemas.reduce(
        (accum, oneFormSchema) => {
            const schema = oneFormSchema.schema;
            const formKeys = Object.keys(schema);
            const oneSchemaFormData = formKeys
                .filter((oneFormKey) => schema[oneFormKey].type !== 'blank-field')
                .reduce(
                    (accum, oneFormKey) => ({
                        ...accum,
                        [oneFormKey]: objectUtil.getObjectFieldByString(oneFormKey, dataObject),
                    }),
                    {},
                );
            return { ...accum, ...oneSchemaFormData };
        },
        { id: dataObject.id },
    );
    return formData;
};

const getFormSchemas = (schema) => {
    return cleanUtil.validate.isArray(schema) ? schema : [{ schema }];
};

export default {
    get,
    post,
    put,
    delete: deleteFunc,
    // graphql,
    getSidFromContext,
    setUrl,
    formDataToObject,
    objectToFormData,
    getFormSchemas,
};
