<template>
    <span>
        <component
            :is="$attrs.is || 'Btn'"
            v-bind="getProps()"
            :class="$attrs.className"
            @click="!$attrs.stopPrevent && ($attrs.confirm ? doConfirm($attrs) : doOnClick())"
            @click.stop.prevent="$attrs.stopPrevent && ($attrs.confirm ? doConfirm($attrs) : doOnClick())"
            :label="$attrs.label"
        />
        <Confirm v-if="$attrs.confirm" :ref="$attrs.name" />
    </span>
</template>
<script>

export default {
    name: 'ToolbarItem',
    inheritAttrs: false,
    data() {
        return {
            allProps: {},
        };
    },
    methods: {
        getProps() {
            const {
                text,
                label,
                name,
                confirm,
                is,
                className,
                modelName,
                icon,
                onClick,
                ...others
            } = this.$attrs;
            return others;
        },
        async doConfirm() {
            const confirmComponent = this.$refs[this.$attrs.name];
            if (await confirmComponent.open(this.$attrs.label || this.$attrs.name, this.$attrs.confirm)) {
                this.$attrs.onClick(this.$attrs);
            }
        },
        doOnClick(val) {
            if (this.$attrs.onClick) {
                this.$attrs.onClick(val);
            }
            this.$emit('click', val);
        },
    },
    mounted() {
        // console.log('mounted', this.$attrs);
    },
};
</script>

<style scoped></style>
