<template>
    <v-container style="min-width: 400px" @dragover.prevent @drop.stop.prevent="dropHandler">
        <v-row class="align-center">
            {{ label }}
            <success-icon :is-success="!!files.length"></success-icon>
            <btn
                @click="toggleCompact"
                v-if="showToggleCompact"
                class="mx-2 float-right mb-1"
                fab
                small
                color="primary"
            >
                <v-icon>{{ isCompact ? 'edit' : 'check' }}</v-icon>
            </btn>
        </v-row>
        <v-row>
            <one-file
                v-for="(fileObj, idx) in files"
                :file-obj="fileObj"
                :key="fileObj.file.id"
                :keyXXX="`${JSON.stringify(fileObj)}-${idx}`"
                @delete-file="deleteFile"
                :allow-delete="!isCompact"
                :class="$attrs.class"
            />
        </v-row>
        <v-row v-if="!isCompact && (multiple || !files.length)">
            <v-file-input v-bind="$attrs" v-model="selectedFile" :accept="accept" :label="label" :multiple="multiple" />
            <btn v-if="allowClipboard" label="Clipboard" @click="clickClipboard" size="small" />
            <btn preset="upload" @click="uploadFile" :disabled="!isFileSelected" />
        </v-row>
    </v-container>
</template>
<script>
import api from '@/api';
import dateUtil from '@/../common/utils/date';
import sequentially from '../../../../../common/utils/sequentially';

import SuccessIcon from '@/components/common/SuccessIcon';

import OneFile from './OneFile';

export default {
    name: 'FileUploadField',
    components: { SuccessIcon, OneFile },
    props: {
        label: String,
        collectionName: String,
        collectionId: String,
        fileType: String,
        compact: Boolean,
        showToggleCompact: Boolean,
        accept: String,
        multiple: Boolean,
        allowClipboard: Boolean,
        formData: Object,
    },
    data() {
        return {
            selectedFile: null,
            files: [],
            isCompact: false,
            useCollectionId: null,
        };
    },
    methods: {
        dropHandler(event) {
            console.log('dropHandler', event.dataTransfer.files[0]);
            if (this.multiple || !this.files.length) {
                console.log('dropHandler file', event.dataTransfer.files[0]);
                this.selectedFile = this.multiple ? event.dataTransfer.files : event.dataTransfer.files[0];
                this.uploadFile();
            }
        },
        // dragOverHandler(event) {
        //     console.log('dragOverHandler', event);
        // },
        async uploadOneFile(file) {
            try {
                console.log('uploadonefile', file);
                //hit 2easy with an urlencoded string to upload the file.
                const uploadFileResult = await api.post(this.$store, 'file', {
                    multiple: this.multiple,
                    file: {
                        metadata: {
                            filename: file.name,
                            mimeType: file.type,
                            size: file.size,
                        },
                        source: {
                            collectionName: this.collectionName,
                            collectionId: this.useCollectionId,
                            fileType: this.fileType,
                        },
                    },
                });
                const gcsUploadResult = await api.put(this.$store, uploadFileResult.data.signedUrl, file, {});
                console.info('gcsUploadResult', gcsUploadResult);
                this.files = uploadFileResult.data.files;
            } catch (e) {}
        },
        async uploadFile() {
            console.log('upload file', this.selectedFile);
            const files = this.multiple ? this.selectedFile : [this.selectedFile];
            if (files[0]) {
                await sequentially.map(files, async (oneFile) => {
                    this.$store.dispatch('setLoading', `Uploading ${oneFile.name}`);
                    await this.uploadOneFile(oneFile);
                });

                // const content = await this.prepareFile();
                this.selectedFile = null;
                this.$store.dispatch('setLoading');
                this.$emit('num-files', this.files.length);
                this.$emit('set-files', this.files);
                this.$emit('input', this.files);
                this.$store.dispatch('setLoading');
            }
        },
        async loadUploadedFile() {
            const url = `file/source?collectionName=${encodeURI(this.collectionName || '')}&collectionId=${encodeURI(
                this.useCollectionId || '',
            )}&fileType=${encodeURI(this.fileType || '')}`;

            console.log('loading file from url', url);
            const loadFileResult = await api.get(this.$store, url);
            console.log({ loadFileResult });
            this.files = loadFileResult.data;
            this.$emit('num-files', this.files.length);
            this.$emit('set-files', this.files);
        },
        async deleteFile(fileId) {
            const deleteResult = await api.delete(this.$store, `file/${fileId}`);
            this.files = this.files.filter((fileObj) => fileObj.file.id !== fileId);
            this.$emit('num-files', this.files.length);
            this.$emit('set-files', this.files);
            this.$emit('input', this.files);
        },
        toggleCompact() {
            this.isCompact = !this.isCompact;
        },
        async clickClipboard() {
            try {
                const permission = await navigator.permissions.query({ name: 'clipboard-read' });
                if (permission.state === 'denied') {
                    throw new Error('Not allowed to read clipboard.');
                }
                const clipboardContents = await navigator.clipboard.read();
                for (const item of clipboardContents) {
                    console.log('item', item.types);
                    if (!item.types.includes('image/png')) {
                        throw new Error('Clipboard contains non-image data.');
                    }
                    const blob = await item.getType('image/png');
                    blob.name = `clipboard-${dateUtil.getFilenameDateStamp(true)}.png`;
                    this.selectedFile = this.multiple ? [blob] : blob;
                    await this.uploadFile();
                    // destinationImage.src = URL.createObjectURL(blob);
                }
            } catch (error) {
                console.error(error.message);
            }
        },
    },
    computed: {
        isFileSelected() {
            if (!this.selectedFile) return false;
            const file = this.multiple ? this.selectedFile[0] : this.selectedFile;
            return file && file.name;
        },
    },
    mounted() {
        this.isCompact = this.compact;
        this.useCollectionId = this.formData && this.formData.id ? this.formData.id : this.collectionId;
        this.loadUploadedFile();
    },
};
</script>
<style>
.v-file-input {
    max-width: 400px;
}
#preview {
    display: flex;
    justify-content: center;
    align-items: center;
}

#preview img {
    max-width: 100%;
    max-height: 500px;
}
</style>
