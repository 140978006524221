<template>
    <v-icon v-if="isSuccess" color="success" v-bind="$attrs">check</v-icon>
    <v-icon v-else color="error" v-bind="$attrs">clear</v-icon>
</template>
<script>
export default {
    name: 'SuccessIcon',
    props: { isSuccess: Boolean },
};
</script>
