<template>
    <span v-if="micro" :class="getClass">
        <success-icon v-if="isSuccess !== null" :isSuccess="true" size="x-small" />
        <fragment v-else>
            {{ formattedValue }}
        </fragment>
    </span>
    <v-input v-else v-bind="$attrs" v-on="$listeners" :class="getClass" :dense="dense" height="20">
        <v-icon v-if="iconCode" class="mt-4 mr-1">
        {{ iconCode }}
        </v-icon>
        <div class="mt-4 nowrap">
            {{ formattedValue }}
        </div>
        <success-icon v-if="isSuccess !== null" :isSuccess="isSuccess" />
    </v-input>
</template>
<script>
import dateUtil from '@/../common/utils/date';
import SuccessIcon from '@/components/common/SuccessIcon';

export default {
    name: 'ReadOnlyField',
    components: { SuccessIcon },
    props: {
        value: String,
        type: String,
        micro: Boolean,
        dense: Boolean,
        isTitle: Boolean,
        iconCode: String,
    },
    data() {
        return {
            isSuccess: null,
            isShowIcon: false,
        };
    },
    methods: {
        formatSwitchField() {
            if (this.value) this.isSuccess = true;
            this.isSuccess = false;
        },
    },
    computed: {
        formatDateField() {
            if (this.$attrs.range) {
                const fromDate = this.value[0];
                const toDate = this.value[1];
                return `${dateUtil.ymdToDmy(fromDate)} ~ ${dateUtil.ymdToDmy(toDate)}`;
                // return dateUtil.ymdToDmy(this.value);
            }
            return dateUtil.ymdToDmy(this.value);
        },

        formattedValue() {
            // if (this.type === 'date-field') return this.formatDateField; // ab this WAS commented adn the release payments date was double formatting ymd
            if (this.component === 'switch-field') return this.formatSwitchField();
            return this.value;
        },
        getClass() {
            const textClass = this.isTitle ? 'px-3 subtitle-1 ' : '';

            if (this.micro) {
                return `micro py-0 ma-0 nowrap`;
            }
            if (this.dense) {
                return `${textClass}pa-0 nowrap`;
            }
            return `${textClass}py-5 px-3 nowrap`;
        },
    },
};
</script>
<style scoped>
.micro {
    /*color: yellow;*/
}
.nowrap {
    white-space: nowrap;
}
</style>
