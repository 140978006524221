<template>
    <v-chip v-if="value" :color="value === 'Error' ? 'error' : 'success'" outlined>
        {{ value || 'Not Found' }}
    </v-chip>
</template>
<script>
export default {
    props: { value: null },
};
</script>
