import Deepmerge from 'deepmerge';

const getValueByKey = (obj, mapping, convertFunc = val => val) =>
    mapping
        ? convertFunc(mapping.split('.').reduce((accum, key) => (accum instanceof Object ? accum[key] : accum), obj))
        : undefined;

const getValueByKeyDefault = (obj, mapping, defaultValue = '', convertFunc = val => val) =>
    getValueByKey(obj, mapping, val => ([undefined, null].includes(val) ? defaultValue : convertFunc(val)));

const getObjectFieldByString = (mapping, obj, convertFunc = val => val) => getValueByKey(obj, mapping, convertFunc);

const initializeField = (mappingArray, newValue) => ({
    [mappingArray[0]]: mappingArray.length > 1 ? initializeField(mappingArray.slice(1), newValue) : newValue,
});

const isObjectMatchesQuery = (obj, query) => {
    const queryKeys = Object.keys(query);

    const result = queryKeys.reduce(
        (accum, oneQueryKey) => accum && getValueByKey(obj, oneQueryKey) === query[oneQueryKey],
        true,
    );
    if (result) return obj;
    return false;
};
const findObjectsMatchingQuery = (records, query) =>
    records.map(oneObj => isObjectMatchesQuery(oneObj, query)).filter(oneObj => oneObj);

const objectToUrl = obj =>
    Object.keys(obj)
        .map(oneKey => `${encodeURIComponent(oneKey)}=${encodeURIComponent(obj[oneKey])}`)
        .join('&');

const setValueByKey = (obj, mapping, newValue, convertFunc) =>
    obj instanceof Object
        ? Deepmerge(
              obj,
              initializeField(mapping ? mapping.split('.') : [], convertFunc ? convertFunc(newValue) : newValue),
          )
        : obj;

const setObjectFieldByString = (mapping, obj, newValue, convertFunc) =>
    setValueByKey(obj, mapping, newValue, convertFunc);

const uniqueArray = (arr, mapping) => {
    if (!mapping) return [...new Set(arr)];
    return arr.reduce((accum, arrItem) => {
        const arrItemValue = getValueByKey(arrItem, mapping);
        const foundItem = accum.find(accumItem => getValueByKey(accumItem, mapping) === arrItemValue);
        return foundItem ? accum : [...accum, arrItem];
    }, []);
};

const transformObject = (mapping, obj) => {
    /*
       mapping eg =  = {
        'companyLink.data.companyCode': 'companyCode',
        properyCode: true, // this is the same as propertyCode: 'propertyCode',
    }
    */

    const useObj = obj ? obj : {};

    const objectKeys = Object.keys(mapping);

    const transformedObject = objectKeys.reduce((accum, oneObjectKey) => {
        const targetObjectKey = mapping[oneObjectKey] === true ? oneObjectKey : mapping[oneObjectKey];

        return setValueByKey(accum, targetObjectKey, getValueByKey(useObj, oneObjectKey));
    }, {});

    return transformedObject;
};

const transformArray = (mapping, arr) => {
    const useArr = arr ? arr : [];
    const transformedArray = useArr.map(oneArr => transformObject(mapping, oneArr));
    return transformedArray;
};

export default {
    findObjectsMatchingQuery,
    getObjectFieldByString,
    getValueByKey,
    getValueByKeyDefault,
    isObjectMatchesQuery,
    objectToUrl,
    setObjectFieldByString,
    setValueByKey,
    uniqueArray,
    transformObject,
    transformArray,
};

// console.info(setObjectFieldByString('alex.hi', { alex: { hii: 'yes', bye: 'no' } }, 'okay'));
// console.info(setValueByKey({ alex: { hii: 'yes', bye: 'no' } }, 'alex.hi', 'okay'));
// console.info(setObjectFieldByString('alex.hi', 'ss', 'okay'));
// console.info(setValueByKey('ss', 'alex.hi', 'okay'));
// console.info(setObjectFieldByString('alex.hi', null, 'okay'));
// console.info(setValueByKey(null, 'alex.hi', 'okay'));
// console.info(setObjectFieldByString('alex.hi', undefined, 'okay'));
// console.info(setValueByKey(undefined, 'alex.hi', 'okay'));
// console.info(setObjectFieldByString('alex.hi', ['ss'], 'okay'));
// console.info(setValueByKey(['ss'], 'alex.hi', 'okay'));
