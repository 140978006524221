<template>
    <component
        :is="$attrs.is || 'v-btn'"
        v-bind="$attrs"
        v-on="{ ...$listeners, click: wrapClick }"
        :icon="componentSettings.icon"
        :id="id"
        :class="`mx-1 ${componentSettings.className}`"
        :color="componentSettings.color"
        :outlined="componentSettings.outlined"
        :small="componentSettings.size === 'small'"
        :xSmall="['x-small', 'xSmall'].includes(componentSettings.size)"
        :large="componentSettings.size === 'large'"
        :xLarge="['x-large', 'xLarge'].includes(componentSettings.size)"
    >
        <v-icon :class="componentSettings.label ? 'mr-2' : ''" v-if="!!componentSettings.iconCode">{{
            componentSettings.iconCode
        }}</v-icon>
        {{ componentSettings.label }}
        <slot />
    </component>
</template>
<script>
import copyToClipboard from '@/utils/copyToClipboard';
import logEventUtil from '@/utils/logEvent';

export default {
    props: {
        label: String,
        size: String,
        className: String,
        id: String,
        preset: String,
        color: String,
        icon: { type: Boolean, default: undefined },
        iconCode: String,
        noOutlined: Boolean,
        clipboardValue: String,
        redirectUrl: String,
    },
    data() {
        return {
            componentSettings: {},
        };
    },
    methods: {
        copyToClipboard,
        async redirect () {
            await this.$router.push(this.redirectUrl);
        },

        async wrapClick(ev) {

            logEventUtil(this, this.$options._componentTag);

            if (this.$listeners && this.$listeners.click) await this.$listeners.click(ev);

            if (this.clipboardValue) {
                await this.copyToClipboard(this.clipboardValue);
            }
            if (this.redirectUrl){
                await this.redirect(this.redirectUrl)
            }
        },
        getPreset() {
            switch (this.preset) {
                case 'all':
                    return {
                        iconCode: 'mdi-view-list-outline',
                        label: 'All',
                        color: 'primary',
                        size: 'small',
                    };
                case 'redirect':
                    return {
                        iconCode: 'mdi-undo',
                        label: 'Done',
                        color: 'primary',
                        size: 'small',
                    };
                case 'clipboard':
                    return {
                        iconCode: 'mdi-clipboard-text',
                        label: 'Clipboard',
                        color: 'grey',
                        size: 'small',
                    };
                case 'search':
                    return {
                        iconCode: 'mdi-database-search',
                        label: 'Search',
                        color: 'primary',
                        size: 'small',
                    };
                case 'save':
                    return {
                        iconCode: 'mdi-content-save-outline',
                        label: 'Save',
                        color: 'success',
                        size: 'small',
                    };
                case 'popup':
                    return {
                        iconCode: 'mdi-arrow-top-right-bottom-left',
                        label: 'More',
                        color: 'grey',
                        size: 'small',
                    };
                case 'add':
                    return {
                        iconCode: 'mdi-plus-box-outline',
                        label: 'Add',
                        color: 'primary',
                        size: 'small',
                    };

                case 'delete':
                    return {
                        iconCode: 'mdi-delete-outline',
                        label: 'Delete',
                        color: 'error',
                        size: 'small',
                    };

                case 'edit':
                    return {
                        iconCode: 'edit',
                        label: 'Edit',
                        color: 'primary',
                        size: 'small',
                    };
                case 'done':
                    return {
                        iconCode: 'mdi-undo',
                        label: 'Done',
                        color: 'warning',
                        size: 'small',
                    };

                case 'cancel':
                    return {
                        iconCode: 'mdi-cancel',
                        label: 'Cancel',
                        color: 'warning',
                        size: 'small',
                    };

                case 'upload':
                    return {
                        iconCode: 'mdi-cloud-upload',
                        label: 'Upload',
                        color: 'primary',
                        size: 'small',
                    };

                case 'ok':
                case 'yes':
                    return {
                        iconCode: 'mdi-check',
                        label: '',
                        color: 'success',
                        size: 'small',
                    };

                case 'no':
                    return {
                        iconCode: 'mdi-window-close',
                        label: '',
                        color: 'error',
                        size: 'small',
                    };

                case 'yesround':
                    return {
                        iconCode: 'mdi-check',
                        label: '',
                        icon: true,
                        color: 'success',
                        size: 'small',
                    };

                case 'noround':
                    return {
                        iconCode: 'mdi-window-close',
                        label: '',
                        icon: true,
                        color: 'error',
                        size: 'small',
                    };
                case 'chevron-left':
                    return {
                        iconCode: 'mdi-chevron-left',
                        icon: true,
                        size: 'x-small',
                    };
                case 'chevron-right':
                    return {
                        iconCode: 'mdi-chevron-right',
                        icon: true,
                        size: 'x-small',
                    };
                case 'chevron-double-left':
                    return {
                        iconCode: 'mdi-chevron-double-left',
                        icon: true,
                        size: 'x-small',
                    };
                case 'chevron-double-right':
                    return {
                        iconCode: 'mdi-chevron-double-right',
                        icon: true,
                        size: 'x-small',
                    };

                default:
                    return {};
            }
        },
        init() {
            const presetSettings = this.getPreset();
            const propSettings = JSON.parse(
                JSON.stringify({
                    iconCode: this.iconCode,
                    size: this.size,
                    icon: this.icon,
                    className: this.className,
                    label: this.label,
                    color: this.color,
                    outlined: this.noOutlined ? false : true,
                }),
            );
            const componentSettings = {
                ...presetSettings,
                ...propSettings,
            };

            if (this.$store.getters.isHideButtonLabel && this.iconCode) {
                componentSettings.label = '';
            }

            if (JSON.stringify(componentSettings) !== JSON.stringify(this.componentSettings)) {
                this.componentSettings = componentSettings;
            }
        },
    },

    updated() {
        this.init();
    },
    mounted() {
        this.init();
    },
};
</script>
