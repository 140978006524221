<template>
    <form-fields
        v-if="localValue"
        v-model="localValue"
        :schema="formSchema"
        :col="12"
        @change="onChange"
        @focus="onFocus"
        @blur="onBlur"
        :micro="micro"
        :key="JSON.stringify(value)"
        filled
    />
</template>
<script>
import FormFields from '@/components/form/DELETEFormFields';

const formSchema = {
    address1: {
        component: 'text-field',
        label: 'Address 1',
        cols: 12,
    },
    address2: {
        component: 'text-field',
        label: 'Address 2',
        cols: 12,
    },
    suburb: {
        component: 'text-field',
        label: 'Suburb',
        cols: 6,
    },
    state: {
        component: 'text-field',
        label: 'State',
        cols: 2,
        readOnly: false,
        autocomplete: {
            type: 'state',
        },
        clearable: true,
        returnObject: false,
    },
    postcode: {
        component: 'text-field',
        label: 'Postcode',
        cols: 3,
    },
};

export default {
    props: {
        value: [Object, String],
        label: String,
        readOnly: Boolean,
        micro: Boolean,
    },
    data() {
        return {
            localValue: null,
            formSchema,
            timeout: null,
        };
    },
    components: { FormFields },
    methods: {
        init() {
            // console.log('address field init', { value: this.value });

            if (this.value === '') {
                // console.log('address field initialise blanks');
                const defaultAddress = Object.keys(this.formSchema).reduce(
                    (accum, oneKey) => ({ ...accum, [oneKey]: '' }),
                    {},
                );
                this.$emit('input', defaultAddress);
                this.$emit('change', defaultAddress);
                // console.log('address field initialise blanks done');
                this.localValue = defaultAddress;
            } else {
                // console.log('address localValue', this.localValue);
                this.localValue = this.value;
            }
        },
        onBlur() {
            // console.log('addressField onBlur');
            this.timeout = setTimeout(() => {
                // console.log('addressField emitted input and change');
                this.$emit('input', this.localValue);
                this.$emit('change', this.localValue);
            }, 0);
        },
        onFocus() {
            // console.log('addressField onFocus');
            clearTimeout(this.timeout);
        },
        onChange(e) {
            // console.log('address changed', e)
            this.localValue = e;
        },
    },
    computed: {},
    updated() {
        // console.log('address updated');
    },
    mounted() {
        // console.log('address mounted', { value: this.value });
        this.init();
    },
};
</script>
