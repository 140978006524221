export default {
    state: {
        isShowLoading: false,
        loadingMessage: null,
        classExtra: null,
        isShowError: false,
        errorMessage: null,
        isShowInfo: false,
        infoMessage: null,
        isHideButtonLabel: false,
    },
    mutations: {
        doSetLoading(state, loadingMessage, classExtra) {
            state.isShowLoading = !!loadingMessage;
            state.loadingMessage = loadingMessage;
            state.classExtra = classExtra;
        },
        doSetError(state, errorMessage) {
            state.isShowError = !!errorMessage;
            state.errorMessage = errorMessage;
        },
        doSetInfo(state, infoMessage) {
            state.isShowInfo = !!infoMessage;
            state.infoMessage = infoMessage;
        },
        doSetHideButtonLabel(state, isHideButtonLabel) {
            state.isHideButtonLabel = isHideButtonLabel;
        },
    },
    actions: {
        setLoading(context, loadingMessage, classExtra) {
            context.commit('doSetLoading', loadingMessage, classExtra);
        },
        setError(context, errorMessage) {
            context.commit('doSetError', errorMessage);
            if (errorMessage) {
                setTimeout(() => context.commit('doSetError'), 10000);
            }
        },
        setInfo(context, infoMessage) {
            context.commit('doSetInfo', infoMessage);
            if (infoMessage) {
                setTimeout(() => context.commit('doSetInfo'), 10000);
            }
        },
        setSuccess(context, successMessage) {
            context.commit('doSetInfo', successMessage);
            if (successMessage) {
                setTimeout(() => context.commit('doSetInfo'), 1500);
            }
        },
        setHideButtonLabel(context, isHideButtonLabel) {
            context.commit('doSetHideButtonLabel', isHideButtonLabel);
        },
    },
    getters: {
        isShowLoading(state) {
            return state.isShowLoading;
        },
        loadingMessage(state) {
            return state.loadingMessage;
        },
        isShowError(state) {
            return state.isShowError;
        },
        errorMessage(state) {
            return state.errorMessage;
        },
        isShowInfo(state) {
            return state.isShowInfo;
        },
        infoMessage(state) {
            return state.infoMessage;
        },
        classExtra(state) {
            return state.classExtra;
        },
        isHideButtonLabel(state) {
            return state.isHideButtonLabel;
        },
    },
};
