var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500","height":"300","align-top":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.isShowAddNote)?_c('v-card',[_c('v-textarea',{staticClass:"pb-0",attrs:{"label":"Enter note"},model:{value:(_vm.newNoteText),callback:function ($$v) {_vm.newNoteText=$$v},expression:"newNoteText"}}),_c('v-container',{staticClass:"pb-1 pt-0"},[(_vm.isNewNote)?_c('toolbar',{attrs:{"left-items":[
                    { id: 'save', onClick: _vm.saveNote },
                    { id: 'done', onClick: _vm.closeDialog },
                ]}}):_c('toolbar',{attrs:{"left-items":[
                    { id: 'save', onClick: _vm.saveNote },
                    { id: 'delete', onClick: _vm.deleteNote },
                    { id: 'cancel', onClick: _vm.closeDialog },
                ]}})],1)],1):_c('v-card',[_c('smart-table',{key:JSON.stringify(_vm.noteData),staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.noteData,"dense":"","itemsPerPage":5,"itemKey":"noteId","single-select":true},on:{"click:row":_vm.noteSelected}}),_c('toolbar',{attrs:{"left-items":[
                { id: 'add', onClick: _vm.showAdd },
                { id: 'done', onClick: _vm.closeDialog },
            ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }