<template>
    <pre v-if="showZero || value" v-bind="$attrs">{{ formatter().format(parseFloat(`${value}`)) }} </pre>
</template>
<script>
export default {
    props: { value: [Number, String], showZero: Boolean },
    methods: {
        formatter() {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });
        },
    },
};
</script>
