// date-fns formatting - https://date-fns.org/v2.23.0/docs/format

import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
import { validate } from './clean';

const timeZone = 'Australia/Sydney';
// const timeZone = 'Pacific/Auckland';
const dateTimeSuffix = 'T00:00:00+11:00';

const UTCTimeSuffix = 'T00:00:00+00:00';

// const timeZone = 'Australia/Perth';
const { parse } = dateFns;
const stringToDate = (dateString, isAllowAll) => {
    const isValidDatePattern = validate.isValidDatePattern(dateString);
    const isValidISODatePattern = validate.isValidISODatePattern(dateString);

    if (!isValidDatePattern && !isValidISODatePattern && !isAllowAll) return null; // Invalid format

    return dateFnsTz.toDate(dateString, {
        timeZone,
    });
};
const dateToZonedDate = useDate => dateFnsTz.utcToZonedTime(useDate, timeZone);

const getDateFromAnyForm = dateInAnyForm => {
    if (validate.isDate(dateInAnyForm)) {
        return dateToZonedDate(dateInAnyForm);
    }

    if (validate.isValidMonthPattern(dateInAnyForm)) return stringToDate(`${dateInAnyForm}-01`);

    if (
        validate.isValidDatePattern(dateInAnyForm) ||
        validate.isValidMonthPattern(dateInAnyForm) ||
        validate.isValidISODatePattern(dateInAnyForm)
    ) {
        return stringToDate(dateInAnyForm);
    }

    return dateInAnyForm;
};
const dateToString = (useDate = new Date()) => {
    const zonedDate = dateToZonedDate(useDate);

    return dateFnsTz.format(zonedDate, 'yyy-MM-dd', {
        timeZone,
    });
};
const dateWrap = (callback, ...params) => {
    const useParams = params.map(oneParam => getDateFromAnyForm(oneParam));

    const result = callback(...useParams);

    return validate.isDate(result) ? dateToString(result) : result;
};
const adjustTzOffset = (useDate, isSubtract) => {
    const tzOffset = dateFnsTz.getTimezoneOffset(timeZone);

    const factor = isSubtract ? -1 : 1;

    return dateFns.addMilliseconds(useDate, factor * tzOffset);
};
const createDateInterval = (
    startDateString,
    endDateString = startDateString,
    paramNames = ['start', 'end'],
    isReturnISODate = false,
) => {
    const endDatePlusOne = dateFns.addDays(getDateFromAnyForm(endDateString), 1);
    const endDate = dateFns.addMilliseconds(endDatePlusOne, -1);

    const interval = dateWrap((start, end) => ({ start, end }), startDateString, dateFns.formatISO(endDate));

    if (isReturnISODate) {
        return {
            [paramNames[0]]: interval.start.toISOString(),
            [paramNames[1]]: interval.end.toISOString(),
        };
    }

    return {
        [paramNames[0]]: interval.start,
        [paramNames[1]]: interval.end,
    };
};
const callDateFns = (functionName, ...params) => {
    const useDateFns = { ...dateFns };
    return dateWrap(useDateFns[functionName], ...params);
};
const calculateIntersectingIntervals = (startDate1, endDate1, startDate2, endDate2) => {
    try {
        const interval1 = createDateInterval(startDate1, endDate1);
        const interval2 = createDateInterval(startDate2, endDate2);

        return callDateFns('getOverlappingDaysInIntervals', interval1, interval2); // add 1 to make it the inclusive of last day (not till the beginning of that day)
    } catch (e) {
        console.error('calculateIntersectingIntervals', { startDate1, endDate1, startDate2, endDate2 }, e.message);
        return 0;
    }
};

const callDateFnsTz = (functionName, ...params) => {
    const useDateFnsTz = { ...dateFnsTz };
    return dateWrap(useDateFnsTz[functionName], ...params);
};

const getTodayString = () => dateToString(new Date());

const createDBQueryDateInterval = (startDateStringParam, endDateStringParam = startDateStringParam) => {
    if (startDateStringParam && !validate.isValidDatePattern(startDateStringParam))
        throw new Error(`createDBQueryDateInterval received an invalid start date. ${startDateStringParam}`);

    if (endDateStringParam && !validate.isValidDatePattern(endDateStringParam))
        throw new Error(`createDBQueryDateInterval received an invalid end date. ${endDateStringParam}`);

    const startDateString = startDateStringParam ? startDateStringParam : getTodayString();

    const endDateString = endDateStringParam ? endDateStringParam : startDateString;

    return createDateInterval(startDateString, endDateString, ['$gte', '$lte'], true);
};

const ymToString = ym => {
    if (!validate.isValidMonthPattern(ym)) return null; // Invalid format
    const date = dateFnsTz.toDate(ym, {
        timeZone: 'Australia/Sydney',
    });
    // return dateFns.format(dateFns.parseISO(ym), 'MMM yyyy');
    return dateFns.format(date, 'MMM yyyy');
};
const ymdToDmy = ymd => {
    if (!ymd || ymd.length !== 10) return '';
    const day = ymd.substr(8, 2);
    const month = ymd.substr(5, 2);
    const year = ymd.substr(0, 4);
    return `${day}/${month}/${year}`;
};

const dateStringToHumanReadable = dateString => {
    if (validate.isValidMonthPattern(dateString)) return ymToString(dateString);
    return ymdToDmy(dateString);
};

const dateStringWrapUTC = dateString => `${dateString}${UTCTimeSuffix}`;

const dateToISOString = initialDate => {
    const useDate = getDateFromAnyForm(initialDate);
    if (useDate && validate.isDate(useDate)) return useDate.toISOString();
    return useDate;
};

const dateToTime = (useDate = new Date()) => {
    const zonedDate = dateToZonedDate(useDate);

    return dateFnsTz.format(zonedDate, 'HH:mm:ss', {
        timeZone,
    });
};

const padNumber = (num, len) => `${1e15}${num}`.slice(-len);

const dmyToYmd = dmy => {
    const dmyArray = dmy.split('/');

    if (!dmyArray || dmyArray.length !== 3) return '';
    const day = dmyArray[0];
    const month = dmyArray[1];
    const year = dmyArray[2];
    return `${year}-${padNumber(parseInt(month, 10), 2)}-${padNumber(parseInt(day, 10), 2)}`;
};

const format = (useDate, dateFormat, useTimeZone = timeZone) => {
    // date-fns formatting - https://date-fns.org/v2.23.0/docs/format

    const fudgeDateForTimeZoneOffset = initialDateValue => {
        const tzMinutesOffset = dateFnsTz.getTimezoneOffset(useTimeZone, initialDateValue) / (60 * 1000);
        const dateMinutesOffset = dateFnsTz.getTimezoneOffset(timeZone, initialDateValue) / (60 * 1000);
        const offsetOffset = tzMinutesOffset - dateMinutesOffset;
        return dateFns.addMinutes(initialDateValue, offsetOffset);
    };

    if (validate.isString(useDate)) {
        if (validate.isValidDatePattern(useDate)) {
            const basicDate = dateFnsTz.toDate(useDate);
            return dateFnsTz.format(basicDate, dateFormat, { timeZone: useTimeZone });
        }
        if (validate.isValidISODatePattern(useDate)) {
            const initialDateValue = dateFnsTz.toDate(useDate, {
                timeZone: useTimeZone,
            });
            const fudgedDate = fudgeDateForTimeZoneOffset(initialDateValue);
            return dateFnsTz.format(fudgedDate, dateFormat, { timeZone: useTimeZone });
        }
    }

    const convertedDate = getDateFromAnyForm(useDate);
    const fudgedDate = fudgeDateForTimeZoneOffset(convertedDate);
    return dateFnsTz.format(fudgedDate, dateFormat, { timeZone: useTimeZone });
};

const getDateRangePresetList = () => ['This financial period', 'Last financial period', 'Financial period before last'];

const getFinancialYearStartAndEnd = (dateString, yearOffset = 0) => {
    const month = callDateFns('getMonth', dateString);
    const year = callDateFns('getYear', dateString);
    const startYear = (month < 6 ? year - 1 : year) + yearOffset;
    return [`${startYear}-07-01`, `${startYear + 1}-06-30`];
};

const getDateRangePresetValues = presetValue => {
    const todayDate = getTodayString();
    switch (presetValue) {
        case 'This financial period':
            return getFinancialYearStartAndEnd(todayDate);
        case 'Last financial period':
            return getFinancialYearStartAndEnd(todayDate, -1);
        case 'Financial period before last':
            return getFinancialYearStartAndEnd(todayDate, -2);
        default:
            return [todayDate, todayDate];
    }
};

const getDateStringFromYearMonth = (yearOrYearMonth, month = 0) => {
    if (!month && validate.isString(yearOrYearMonth)) {
        const useYearMonth = `${yearOrYearMonth}`;

        if (useYearMonth.length === 10) return `${useYearMonth}${dateTimeSuffix}`;

        return `${useYearMonth}-01${dateTimeSuffix}`;
    }

    if (!month) throw new Error('Invalid parameters function getDateStringFromYearMonth');

    return `${yearOrYearMonth}-${padNumber(month, 2)}-01${dateTimeSuffix}`;
};

const getDistance = dateStringValue => {
    try {
        const calcDate = new Date(dateStringValue);
        const todayDate = new Date();
        return callDateFns('formatDistance', calcDate, todayDate, { addSuffix: true });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
const getStartAndEndOfMonth = (yearOrYearMonth, month) => {
    const dateString = getDateStringFromYearMonth(yearOrYearMonth, month);

    const daysInMonth = dateFns.getDaysInMonth(new Date(`${dateString.substr(0, 7)}-02`)); // using 02 so that no matter what the timezone difference is, it doesn't go back to the previous month
    const startDate = `${dateString.substr(0, 7)}-01`;
    const endDate = `${dateString.substr(0, 7)}-${daysInMonth}`;

    return {
        startDate,
        endDate,
    };
};
const getEndOfMonth = (yearOrYearMonth, month) => {
    const { endDate } = getStartAndEndOfMonth(yearOrYearMonth, month);

    return endDate;
};

const getFilenameDateStamp = (isIncludeTime = false) => {
    const dateString = dateToString();

    const strippedDate = dateString.replace(/-/g, '');

    if (!isIncludeTime) return strippedDate;

    const timeString = dateToTime();

    const strippedTime = timeString.replace(/:/g, '');

    return `${strippedDate}-${strippedTime}`;
};

const getFirstDateOfMonth = (year, month) => `${year}-${padNumber(month, 2)}-01`;

const getLastDateOfMonth = (year, month) => {
    const lastDay = dateFns.getDaysInMonth(new Date(year, month - 1));
    return `${year}-${padNumber(month, 2)}-${lastDay}`;
};

// const getMonthStats = (year, month) => {
//     const numberYear = parseInt(`${year}`, 10);
//     const numberMonth = parseInt(`${month}`, 10);
//
//     const financialYearStartDate = `${numberMonth > 6 ? numberYear : numberYear - 1}-07-01`;
//     const financialYearEndDate = `${numberMonth > 6 ? numberYear + 1 : numberYear}-06-30`;
//
//     const monthStartDate = `${padNumber(numberYear, 4)}-${padNumber(numberMonth, 2)}-01`;
//     const monthEndDate = getLastDateOfMonth(numberYear, numberMonth);
//
//     throw new Error('check end date format is it ymd or dmy ???? ');
// };

const getNextDay = (dateStringValue, isAdd = true) => callDateFns('addDays', dateStringValue, isAdd ? 1 : -1);

const getNextMonth = (dateStringValue, isAdd = true) => {
    const newDate = callDateFns('addMonths', dateStringValue, isAdd ? 1 : -1);
    const isMonthDate = dateStringValue.length === 7;
    return isMonthDate ? newDate.substring(0, 7) : newDate;
};

function getNextWeekDayDate(inputDate, dayOfWeek) {
    const dayIntegerLookup = ['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const startOfWeekDayIndex = (dayIntegerLookup.indexOf(dayOfWeek) + 1) % 7;

    if (startOfWeekDayIndex === -1) throw new Error('getNextWeekDayDate need a day such as "Monday"');

    const useDate = inputDate || getTodayString();

    return callDateFns('lastDayOfWeek', useDate, {
        weekStartsOn: startOfWeekDayIndex,
    });
}

const getOverlappingDateStats = (baseStartDate, baseEndDate, compareStartDate, compareEndDate) => {
    const totalDays = callDateFns('differenceInCalendarDays', baseEndDate, baseStartDate);
    const baseInterval = createDateInterval(baseStartDate, baseEndDate);
    const compareInterval = createDateInterval(compareStartDate, compareEndDate);
    const overlappingDays = callDateFns('getOverlappingDaysInIntervals', baseInterval, compareInterval);
    const ratio = overlappingDays && totalDays ? overlappingDays / totalDays : 0;

    return {
        totalDays,
        overlappingDays,
        ratio,
    };
};

const getStartOfMonth = (yearOrYearMonth, month) => {
    const { startDate } = getStartAndEndOfMonth(yearOrYearMonth, month);

    return startDate;
};

const periodToMonth = (financialYear, financialPeriod) => {
    let month = financialPeriod - 6;
    let year;
    if (month > 0) year = financialYear;
    else {
        year = financialYear - 1;
        month += 12;
    }

    const yearMonth = `${year}-${padNumber(month, 2)}`;

    return {
        year,
        month,
        yearMonth,
    };
};

const tzToUtc = useDate => adjustTzOffset(useDate, true);

const utcToTz = useDate => adjustTzOffset(useDate, false);

const monthsArray = [
    { text: 'January', value: '1' },
    { text: 'February', value: '2' },
    { text: 'March', value: '3' },
    { text: 'April', value: '4' },
    { text: 'May', value: '5' },
    { text: 'June', value: '6' },
    { text: 'July', value: '7' },
    { text: 'August', value: '8' },
    { text: 'September', value: '9' },
    { text: 'October', value: '10' },
    { text: 'November', value: '11' },
    { text: 'December', value: '12' },
];

export default {
    calculateIntersectingIntervals,
    callDateFns,
    callDateFnsTz,
    createDateInterval,
    createDBQueryDateInterval,
    dateStringWrapUTC,
    dateStringToHumanReadable,
    dateToISOString,
    dateToString,
    dateToTime,
    dmyToYmd,
    format,
    getDateRangePresetList,
    getDateRangePresetValues,
    getDistance,
    getEndOfMonth,
    getFirstDateOfMonth,
    getFilenameDateStamp,
    getFinancialYearStartAndEnd,
    getLastDateOfMonth,
    getNextDay,
    getNextMonth,
    getOverlappingDateStats,
    getStartAndEndOfMonth,
    getStartOfMonth,
    getNextWeekDayDate,
    getTodayString,
    parse,
    periodToMonth,
    stringToDate,
    tzToUtc,
    utcToTz,
    ymdToDmy,
    ymToString,
    monthsArray,
};
