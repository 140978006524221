<template>
    <div>
        <div class="d-flex">
            <text-field v-bind="$attrs" :label="label" v-model="newValue" @change="addItem">
                <template v-slot:append-outer> <btn preset="add" /> </template>
            </text-field>
        </div>
        <div class="d-flex flex-wrap">
            <v-chip
                v-for="(item, counter) in localValue"
                :key="`${label} ${item} ${counter}`"
                close
                @click:close="removeItem(counter)"
            >
                {{ item }}
            </v-chip>
        </div>
    </div>
</template>
<script>
import sortUtil from '@/../common/utils/sort';

export default {
    props: {
        value: [Array, String],
        label: String,
        readOnly: Boolean,
    },
    data() {
        return {
            localValue: [],
            newValue: '',
        };
    },
    methods: {
        addItem(newValue) {
            if (newValue) {
                if (this.localValue && this.localValue.includes(newValue)) {
                    this.$store.dispatch(
                        'setError',
                        `${this.label} already contains value - ${newValue}`,
                    );
                } else {
                    this.localValue = sortUtil([...this.localValue, this.newValue]);
                    this.$emit('input', this.localValue);
                    this.$emit('change', this.localValue);
                }
                this.newValue = '';
            }
        },
        removeItem(counter) {
            const removedItem = this.localValue.splice(counter, 1);
            this.$emit('input', this.localValue);
            this.$emit('change', this.localValue);
            this.$store.dispatch('setSuccess', `Removed ${this.label} value - ${removedItem}`);
        },
    },
    mounted() {
        this.localValue = this.value;
    }
};
</script>
