/*
    Usage:
        from
        await Promise.all(myArray.map(item => xService.save(context, item)));

        to
        await sequentially.map(myArray, item => xService.save(context, item));
 */

const sequentiallyMap = async (array, callback) => {
    const returnValues = [];
    for (let index = 0; index < array.length; index += 1) {
        // eslint-disable-next-line no-await-in-loop
        const returnValue = await callback(array[index], index);
        returnValues.push(returnValue);
    }
    return returnValues;
};

function createChunks(array, chunkSize) {
    const chunks = [];
    const n = array.length;
    let i = 0;

    while (i < n) {
        chunks.push(array.slice(i, (i += chunkSize)));
    }
    return chunks;
}

const chunkMap = async (array, chunkSize, callback) => {
    const chunks = createChunks(array, chunkSize);
    const returnArray = [
        ...(await sequentiallyMap(chunks, (chunk, index) =>
            Promise.all(chunk.map((chunkItem, chunkIndex) => callback(chunkItem, index * chunkSize + chunkIndex))),
        )),
    ].flat(1);
    console.log(
        '   CHUNKED ARRAY LENGTH  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',
        returnArray.length,
        JSON.stringify(returnArray[0], null, 2),
    );
    return returnArray;
};

const sequentiallyReduce = (array, initialValue, callback) =>
    array.reduce(async (accum, oneItem, idx) => {
        const accumValue = await accum;
        return callback(accumValue, oneItem, idx);
    }, Promise.resolve(initialValue));

// // Optionally, you can do the following to avoid cluttering the global namespace:
// Array.chunk = chunk;

export default {
    map: sequentiallyMap,
    chunkMap,
    reduce: sequentiallyReduce,
};
