import api from '../api';

export default {
    state: {},
    mutations: {},
    actions: {
        async connectGoogle(context) {
            try {
                console.log('connectGoogle 1', context);
                const data = await api.post(context, 'google/connectgoogle', {});
                console.log('connectGoogle 2', JSON.stringify(data.data, null, 2));
                return data.data.redirectTo;
            } catch (e) {
                return `?errmsg=Error Retrieving Google Data`;
            }
        },
        async completeGoogle(context, inputData) {
            try {
                const data = await api.post(context, 'google/completegoogle', inputData);
                console.log('returned from completegoogle', data);
                return data.data.data.completeQBCompany;
            } catch (e) {
                return 'complete failed';
            }
        },
    },
    getters: {},
};
