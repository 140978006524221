<template>
    <fragment>
        <text-field
            v-if="isFilter"
            v-model="useFilterText"
            label="Filter Results"
            class="mx-4 col-6 col-sm-12"
        />
        <slot name="before-table" />
        <v-data-table
            :headers="headers"
            :items="filteredItems"
            class="elevation-0"
            :items-per-page="itemsPerPage || 20"
            :footer-props="{
                ...useFooterProps,
            }"
            :light="light"
            :dense="dense"
            :show-select="showSelect"
            :single-select="singleSelect"
            :item-key="itemKey"
            :value="value"
            :page="pageNumber"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :hide-default-footer="hideDefaultFooter"
            :show-expand="showExpand"
            :single-expand="singleExpand"
            :expanded.sync="expandedSync"
            :key="useKey"
            @click:row="onClickRow"
            @change="valueChanged"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <!--
             v-on="$listeners" firing other named triggers twice. we only need the clickRow to be
             named as that has custom behaviour behind it... delete the others as they simply bubble
             up the event.
            -->
            <template
                v-for="customField of customFields"
                :slot="`item.${customField.fieldName}`"
                slot-scope="{ item, header }"
            >
                <component
                    :is="customField.customComponent"
                    :item="item"
                    :value="getCustomFieldValue(customField.fieldName, item)"
                    v-bind="header"
                    :key="customField.fieldName"
                />
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <slot name="expanded-item" :item="item" :headers="headers"></slot>
            </template>
            <template v-slot:item.data="{ item }">
                <slot name="item.data" :item="item"></slot>
            </template>
            <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                <slot :name="name" v-bind="slotData" />
            </template>
        </v-data-table>
        <slot name="after-table" />
    </fragment>
</template>

<script>
import objectUtil from '@/../common/utils/object';

import Currency from '../format/Currency';
import CodeChip from '../format/CodeChip';
import DateTime from '../format/DateTime';

export default {
    components: { Currency, CodeChip, DateTime },
    props: {
        items: Array,
        headers: Array,
        light: Boolean,
        dense: Boolean,
        itemsPerPage: Number,
        showSelect: Boolean,
        singleSelect: Boolean,
        selectedRow: Array,
        pageNumber: Number,
        sortBy: [String, Array],
        sortDesc: [Boolean, Array],
        hideDefaultFooter: Boolean,
        showExpand: Boolean,
        singleExpand: Boolean,
        itemKey: [String, Number, Array, Object],
        isFilter: Boolean,
        useClickRowExpand: Boolean,
        footerProps: { type: Object, default: () => ({}) },
        itemsPerPageOptions: { type: Array, default: () => [10, 20, 30, 40] },
        value: [String, Array],
        filterText: String,
    },
    data() {
        return {
            expandedSync: [],
            filteredItems: [],
            useKey: '',
            useFooterProps: {},
            debounceTimer: null,
            useFilterText: '',
        };
    },
    computed: {
        customFields() {
            return this.headers
                .filter((oneHeader) => oneHeader.customComponent)
                .map((oneHeader) => ({
                    fieldName: oneHeader.value,
                    customComponent: oneHeader.customComponent,
                }));
        },
    },
    watch: {
        useFilterText() {
            this.$emit('update:filter-text', this.useFilterText);
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(() => {
                this.updateFilteredItems(this.useFilterText);
            }, 500);
        },
    },
    methods: {
        onClickRow(row, { expand, ...rest }) {
            expand(
                this.expandedSync.find((oneRow) => JSON.stringify(oneRow) === JSON.stringify(row))
                    ? null
                    : row,
            );
        },
        updateFilteredItems(val) {
            const useItems = this.items && this.items.length ? this.items : [];
            if (this.isFilter) {
                this.filteredItems = useItems.filter(
                    (oneItem) =>
                        !val ||
                        (oneItem.searchFields &&
                            oneItem.searchFields.toUpperCase().includes(val.toUpperCase())),
                );
            } else {
                this.filteredItems = [...useItems];
            }
            this.updateKey();
        },
        updateKey() {
            this.useKey = JSON.stringify({
                useFilterText: this.useFilterText,
                items: this.filteredItems,
            });
        },
        getCustomFieldValue(mapping, obj) {
            return objectUtil.getObjectFieldByString(mapping, obj);
        },
        valueChanged(newValue) {
            this.$emit('input', newValue);
        },
        init() {
            this.useFilterText = this.filterText || '';
            console.log('******** smart table init filter', this.useFilterText);
            this.useFooterProps = { ...this.footerProps };
            if (this.itemsPerPageOptions) {
                this.useFooterProps['items-per-page-options'] = this.itemsPerPageOptions;
            }
            this.updateFilteredItems(this.filterText);
        },
    },
    updated() {
        console.log(
            'updated smart table',
            this.useFilterText,
            this.filterText,
            this.useFilterText === this.filterText,
        );
        if (typeof this.filterText !== 'undefined' && this.useFilterText !== this.filterText) {
            console.log('updating smart table');
            this.useFilterText = this.filterText;
            this.updateFilteredItems(this.useFilterText);
        }
    },
    mounted() {
        this.init();
    },
};
</script>
