<template>
    <v-app
        id="sandbox"
        :dark="dark"
        :style="$vuetify.theme.dark ? 'background: #141414;' : 'background: #ECEFF1;'"
    >
        <v-main>
            <router-view />
        </v-main>
        <!--        <v-footer :inset="footer.inset" app>-->
        <!--            <span class="px-3">&copy; {{ new Date().getFullYear() }}</span>-->
        <!--        </v-footer>-->
    </v-app>
</template>

<script>
export default {
    data: () => ({
        dark: true,
        footer: {
            inset: false,
        },
    }),
};
</script>
<style>
    @media print
    {
        .no-print, .no-print *
        {
            display: none !important;
        }
    }

    .rounded-border {
        border: 1px solid #80808050;
        border-radius: 8px;
        padding: 12px;
    }

    .constrain-width {
        max-width: 1300px;
    }

</style>