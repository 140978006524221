import api from '../api';

export default {
    state: {},
    mutations: {},
    actions: {
        async testAcrossModules(context, payload) {
            console.log(context.getters.sid, payload);
        },
    },
    getters: {},
};
