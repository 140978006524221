<template>
    <pre v-if="value">{{ formatter(value) }} </pre>
</template>
<script>
import dateUtil from '@/../common/utils/date';
export default {
    props: { value: String, hideTime: Boolean },
    methods: {
        formatter(value) {
            const useDate = dateUtil.dateToString(value);
            const useTime = this.hideTime ? '' : dateUtil.dateToTime(value);
            return `${useDate} ${useTime}`;
        },
    },
};
</script>
