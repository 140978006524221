<template>
    <div class="my-0 py-1 mr-5">
        <read-only-field v-bind="$attrs" :value="dateValue" v-if="readOnly" />
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            class="mx-2 nowrap"
            v-else
        >
            <template v-slot:activator="{ on }">
                <v-row class="py-0 my-0 mr-4">
                    <v-container class="d-flex justify-start py-0 px-2 align-top flex-wrap">
                        <span>
                            <text-field
                                v-bind="$attrs"
                                :value="dateText"
                                icon-code="event"
                                readOnly
                                @input="updateValue"
                                v-on="on"
                                class="bottom"
                            />
                        </span>
                        <span v-if="dateText && !readOnly && month && !range" class="d-flex pl-3 pt-2">
                            <btn v-if="$attrs.clearable" size="x-small" icon @click="clearDate">
                                <v-icon>clear</v-icon>
                            </btn>
                            <btn preset="chevron-left"  @click="clickNextMonth(false)" />
                            <btn preset="chevron-right"  @click="clickNextMonth(true)" />

                        </span>
                        <span v-if="dateText && !readOnly && !month && !range" class="d-flex pl-3 pt-2">
                            <btn v-if="$attrs.clearable" size="x-small" icon @click="clearDate">
                                <v-icon>clear</v-icon>
                            </btn>
                            <btn preset="chevron-double-left" @click="clickNextMonth(false)"/>
                            <btn preset="chevron-left" @click="clickNextDay(false)"/>
                            <btn preset="chevron-right" @click="clickNextDay(true)"/>
                            <btn preset="chevron-double-right" @click="clickNextMonth(true)"/>
                        </span>
                    </v-container>
                </v-row>
            </template>
                <v-card class="text-center" style="z-index: 500;">
                    <v-date-picker
                        v-model="dateValue"
                        no-title
                        :range="range"
                        :type="month ? 'month' : 'date'"
                        @input="range ? null : dateValueChanged(dateValue)"
                        @change="dateChange"
                    >
                        <v-container v-if="showPresets || range">
                            <v-row v-if="showPresets">
                                <v-col>
                                    <select-field
                                        :items="presets"
                                        label="Presets"
                                        :value="presetValue"
                                        @input="updatePreset"
                                        cols="auto"
                                        :key="presetValue"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-spacer></v-spacer>
                                <btn text color="primary" @click="menu = false" small>Cancel</btn>
                                <btn text color="primary" @click="dateValueChanged(dateValue)" small>OK</btn>
                            </v-row>
                        </v-container>
                    </v-date-picker>
                </v-card>
        </v-menu>
    </div>
</template>
<script>
import dateUtil from '@/../common/utils/date';

export default {
    props: {
        label: String,
        range: Boolean,
        initialFromDate: String,
        initialToDate: String,
        value: [String, Array],
        month: Boolean,
        showPresets: Boolean,
        today: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        dateValue: null,
        fromDate: null,
        toDate: null,
        menu: false,
        presets: [],
        presetValue: '',
    }),
    computed: {
        dateText() {
            if (!this.fromDate) return '';
            const fromDateText = dateUtil.dateStringToHumanReadable(this.fromDate);
            const toDateText = dateUtil.dateStringToHumanReadable(this.toDate);
            if (!fromDateText) return '';
            if (fromDateText === toDateText || !this.range) return fromDateText;
            return `${fromDateText} ~ ${toDateText}`;
        },
    },
    methods: {
        dateValueInitial(dateValueFromString, dateValueToString = dateValueFromString) {
            const dateValue = this.range ? [dateValueFromString, dateValueToString] : dateValueFromString;
            this.dateValue = this.month ? dateValue.substr(0, 7) : dateValue;
            this.dateValueChanged(this.dateValue);
        },
        setDateValue(fromDate, toDate) {
            this.fromDate = fromDate;
            this.toDate = toDate;
            this.$emit('input', this.range ? [this.fromDate, this.toDate] : this.fromDate);
            this.$emit('fromDate', this.fromDate);
            this.$emit('toDate', this.toDate);
            this.$emit('dateValue', this.dateValue);
            this.$emit('change', this.range ? [this.fromDate, this.toDate] : this.fromDate);
            this.menu = false;
            if (this.$refs.dialog) this.$refs.dialog.save(this.dateValue);
        },
        dateValueChanged(dateValue) {
            this.dateValue = dateValue;
            if (dateValue) {
                if (this.range) {
                    dateValue.sort();

                    const [fromDate, toDate] = dateValue;

                    this.setDateValue(fromDate, toDate);
                } else {
                    this.setDateValue(dateValue);
                }
            }
        },
        clearDate() {
            console.log('clearDate');
            this.updateValue(null);
        },
        updatePreset(event) {
            this.presetValue = event;
            try {
                const dateRange = dateUtil.getDateRangePresetValues(this.presetValue);
                const useDateRange = this.month ? [dateRange[0].substr(0, 7), dateRange[1].substr(0, 7)] : dateRange;
                this.dateValueChanged(useDateRange);
            } catch (e) {}
        },
        dateChange(e) {
            this.presetValue = '';
        },
        getDefaultDate() {
            const todayString = dateUtil.getTodayString();
            return this.month ? todayString.substring(0, 7) : todayString;
        },
        clickNextMonth(isAdd) {
            const dateValue = this.dateValue || this.getDefaultDate();
            this.dateValueChanged(dateUtil.getNextMonth(dateValue, isAdd));
        },
        clickNextDay(isAdd) {
            const dateValue = this.dateValue || this.getDefaultDate();
            this.dateValueChanged(dateUtil.getNextDay(dateValue, isAdd));
        },
        updateValue(val) {
            if (val === null) {
                if (this.range) {
                    this.setDateValue(null, null);
                } else {
                    this.setDateValue(null);
                }
            }
        },
    },
    async mounted() {
        //todo need to accommodate the empty date concept
        console.log('******************************************');
        console.log('DATE FIELD - need to accommodate the empty date concept');
        console.log('******************************************');

        this.presets = dateUtil.getDateRangePresetList();
        if (this.value) {
            if (this.range) {
                this.fromDate = this.value[0];
                this.toDate = this.value[1];
                this.dateValueInitial(this.fromDate, this.toDate);
            } else {
                this.fromDate = this.value;
                this.toDate = this.value;
                this.dateValueInitial(this.value);
            }
        } else {
            const todayString = this.today ? dateUtil.getTodayString() : '';
            this.fromDate = this.initialFromDate || todayString;
            this.toDate = this.initialToDate || todayString;
            this.dateValueInitial(todayString);
        }
    },
};
</script>
