<template>
    <v-navigation-drawer
        v-if="isAuthenticated"
        mobile-breakpoint="300"
        v-model="drawer"
        app
        clipped
        :XXXXexpand-on-hover="menuExpandOnHover"
        mini-variant-width="80"
        :style="useStyle"
        dark
    >
        <v-list dense nav class="py-1">
            <v-list-item two-line class="rounded-0" style="background-color: rgba(255,255,255,0.2);">
                <!--<v-list-item two-line class="rounded-0" style="background: rgb(2,0,36); background: linear-gradient(338deg, rgba(0,212,255,1) 0%, rgba(0,212,255,1) 35%, rgba(0,212,255,1) 100%);">-->
                <v-icon
                    x-large
                    class="ma-0 pa-2 ma-1"
                    color="white"
                    >mdi-office-building-marker-outline</v-icon
                >
                <!--                <Avatar :username="user.userCode" xbackground-color="white" />-->
                <v-list-item-content>
                    <v-list-item-title class="d-flex align-center" >
                        <v-spacer></v-spacer>
                        <span class="font-weight-light text-h5 ma-0">rPro</span>
                        <v-spacer></v-spacer>
                        <!--                        <btn x-small icon @click.stop="menuExpandOnHover = !menuExpandOnHover">-->
                        <!--                            <v-icon size="15">{{-->
                        <!--                                menuExpandOnHover ? 'mdi-pin-off' : 'mdi-pin'-->
                        <!--                            }}</v-icon>-->
                        <!--                        </btn>-->
                    </v-list-item-title>
                    <v-list-item-title align="center">
                        <v-spacer></v-spacer>
                        <h4>{{ user.name }}</h4>
                    </v-list-item-title>

                    <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-menu
                open-on-hover
                xxdark
                v-for="item in items"
                :key="item.title"
                :offset-x="true"
                right
                fixed
                content-class="elevation-0"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                        :link="!!item.link"
                        @click="navigateTo(item)"
                        v-bind="$attrs"
                        v-on="on"
                    >
                        <a :href="item.link" class="d-flex menu-link">
                            <div class="d-flex menu-item">
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </div>
                        </a>
                    </v-list-item>
                </template>
                <v-list v-if="item.children" :color="color" light dense>
                    <v-list-item
                        v-for="child in item.children"
                        :key="child.title"
                        link
                        @click="navigateTo(child)"
                    >
                        <a :href="child.link" class="d-flex menu-link menu-link-child" style="">
                            <div class="d-flex menu-item">
                                <v-list-item-icon>
                                    <v-icon light>{{ child.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title>{{ child.title }}</v-list-item-title>
                            </div>
                        </a>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-list-item @click="logoutUser">
                <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Log Out</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider />
        </v-list>
        <v-layout>
            <v-spacer />
            <v-switch v-model="dark" label="Dark Theme" @change="toggleDarkTheme" />
            <v-spacer />
        </v-layout>
    </v-navigation-drawer>
</template>

<script>
import api from '@/api';

import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        drawer: Boolean,
    },
    data() {
        return {
            items: [],
            menuExpandOnHover: true,
            XXXXcolor: '#37474f',
            color: '#bbb',
            useStyle: 'background: rgb(2,0,36); background: linear-gradient(338deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);',
            dark: null,
        };
    },
    methods: {
        navigateTo(item) {
            console.log('menu item', item);
            if (item.link) {
                if (this.$route.path !== item.link) this.$router.push(item.link);
            }
        },
        testLoggedIn() {
            console.log('testLoggedIn', this.isAuthenticated);
            console.log('user', this.user);
        },
        ...mapActions(['logoutUser', 'setColor']),
        async getMenu() {
            const menuResult = await api.get(this.$store, 'menu');
            if (menuResult.data) {
                this.items = menuResult.data.items;
                this.serverEnvironment = menuResult.data.serverEnvironment;
                this.color = this.serverEnvironment === 'live' ? '#fff' : '#f60';
                this.setColor(this.color);
            }
        },
        toggleDarkTheme(val) {
            console.log('Dark theme', val);
            this.$store.dispatch('setDark', val);
            this.$vuetify.theme.dark = val;
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'user']),
    },
    async created() {
        this.dark = this.$store.getters.dark;
        this.$vuetify.theme.dark = this.dark;
        this.testLoggedIn();
        await this.getMenu();
        console.log('loaded menu');
    },
};
</script>
<style scoped>
.menu-link {
    color: white;
    text-decoration: none;
}
.menu-link-child {
    color: black;
}

.menu-item {
    width: 240px;
}
</style>
