import objectUtil from './object.js';

const sortUtil = (data, sortObj) => {
    //  sortObj  = { someKey: 1, 'another.key': -1 }
    if (!sortObj) {
        return data.sort((a, b) => (a > b ? 1 : -1));
    }

    const sortKeys = Object.keys(sortObj);

    const sortItems = sortKeys.map((oneKey) => ({ key: oneKey, value: sortObj[oneKey] })); // [{someKey: 1}, {anotherKey: -1}]

    return data.sort(
        (a, b) =>
            sortItems.reduce((accum, oneItem) => {
                if (accum) return accum;

                const aValue = objectUtil.getObjectFieldByString(oneItem.key, a);
                const bValue = objectUtil.getObjectFieldByString(oneItem.key, b);
                const isGreater = aValue > bValue;
                const isEqual = aValue === bValue;

                if (accum === null && isEqual) return null;

                return isGreater ? oneItem.value : oneItem.value * -1;
            }, null) || 1,
    );
};

export default sortUtil;
