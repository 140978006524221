<template>
    <pre v-bind="$attrs">{{ displayValue }}</pre>
</template>

<script>
import objectUtil from '@/../common/utils/object';

export default {
    props: {
        value: [Object, String, Number, Array],
        mapping: String,
    },
    computed: {
        displayValue() {
            if (this.mapping) {
                return JSON.stringify(
                     objectUtil.getObjectFieldByString(this.mapping, this.value),
                    null,
                    2,
                );
            }
            return JSON.stringify(this.value, null, 2);
        },
    },
};
</script>
