<template>
    <btn v-if="requestType === 'download'" @click="getTokenAndDownloadReport" :label="useLabel" />
    <btn v-else-if="requestType === 'email'" @click="emailReport" :label="useLabel" />
    <btn v-else-if="requestType === 'print'" @click="getTokenAndPrintReport" :label="useLabel" />
</template>

<script>
import fileDownload from 'js-file-download';
import axios from 'axios';
import printJS from 'print-js';

import api from '@/api';
import cleanUtil from '@/../common/utils/clean';

export default {
    props: {
        runningTitle: String,
        filename: {
            type: String,
            default: 'download.pdf',
        },
        mime: {
            type:String,
            default: 'application/pdf',
        },
        runItems: Array,
        requestType: {
            type: String,
            default: 'download',
        },
        options: Object,
        label: String,
    },
    data() {
        return {
            useLabel: null,
        };
    },
    methods: {
        async getReportDownloadToken() {
            const result = await api.post(this.$store, 'report/run', {
                runItems: this.runItems,
            });
            return {
                token: result.data.token,
                url: result.data.url,
            };
        },
        async downloadReport(url, token) {
            const result = await axios.get(url, {
                responseType: 'blob',
                headers: {
                    token,
                },
            });
            if (cleanUtil.validate.isBlob(result.data)) {
                fileDownload(result.data, this.filename, this.mime);
            } else {
                this.$store.dispatch('setError', result.data.errorMessage);
            }
        },
        async printReport(url, token) {
            const result = await axios.get(url, {
                responseType: 'arraybuffer',
                headers: {
                    token,
                },
            });
            if (cleanUtil.validate.isArrayBuffer(result.data)) {
                const reportBuffer = result.data;
                const report64 = Buffer.from(reportBuffer, 'binary').toString('base64');
                printJS({ printable: report64, type: 'pdf', base64: true });
            } else {
                this.$store.dispatch('setError', result.data.errorMessage);
            }
        },
        async emailReport() {
            if (this.runningTitle) {
                this.$store.dispatch('setLoading', this.runningTitle);
            }

            const result = await api.post(this.$store, 'report/run', {
                runItems: this.runItems,
                requestType: this.requestType,
                options: this.options,
            });

            this.$store.dispatch('setLoading');

            const { emailServerResponse } = result.data;
            if (emailServerResponse.success) {
                this.$store.dispatch('setInfo', 'Email Sent');
            } else {
                this.$store.dispatch('setError', emailServerResponse.response);
            }
        },
        async getTokenAndDownloadReport() {
            if (this.runningTitle) {
                this.$store.dispatch('setLoading', this.runningTitle);
            }

            const { url, token } = await this.getReportDownloadToken();
            await this.downloadReport(url, token);

            this.$store.dispatch('setLoading');
        },
        async getTokenAndPrintReport() {
            if (this.runningTitle) {
                this.$store.dispatch('setLoading', this.runningTitle);
            }

            const { url, token } = await this.getReportDownloadToken();
            await this.printReport(url, token);

            this.$store.dispatch('setLoading');
        },
    },
    created() {
        const defaultLabel = {
            download: 'Download Report',
            email: 'Email Report',
            print: 'Print Report',
        };

        this.useLabel = this.label || defaultLabel[this.requestType];
    },
};
</script>
