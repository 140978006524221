<template>
    <fragment>
        <component :is="landscape ? 'landscape-page' : 'portrait-page'" v-bind="$attrs">
            <!-- Pass on all named slots -->
            <slot v-for="slot in Object.keys($slots)" :name="slot" v-slot="slot" />
            <!-- Pass on all scoped slots ! -->
            <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
                <slot :name="name" v-bind="data"></slot>
            </template>
        </component>
    </fragment>
</template>
<script>
import PortraitPage from '@/components/common/PortraitPage';
import LandscapePage from '@/components/common/LandscapePage';

export default {
    components: { PortraitPage, LandscapePage },
    props: {
        landscape: Boolean,
    },
};
</script>
