import cleanUtil from '../../common/utils/clean';

const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    let result;
    try {
        const successful = document.execCommand('copy');
        result = successful ? 'successful' : 'unsuccessful';
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
    return result;
}
const textCopied = async (e) => {
    let writeable;
    if (cleanUtil.validate.isString(e)) {
        writeable = e;
    } else {
        writeable = JSON.stringify(e, null, 2);
    }

    if (navigator.clipboard) {
        await navigator.clipboard.writeText(writeable);
    } else {
        fallbackCopyTextToClipboard(writeable);
    }
    // this.hintValue = this.textHint || 'Copy to clipboard';
}

export default  textCopied
