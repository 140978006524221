<template>
    <v-container v-if="insideContainer" fill-height>
        <v-layout column justify-center align-center>
            <v-progress-circular
                indeterminate
                :size="70"
                :width="7"
                :color="progressColor"
            />
            <h1 v-if="message != null">{{ message }}</h1>
        </v-layout>
    </v-container>
    <v-layout v-else row justify-center>
        <v-dialog v-model="value" persistent content content-class="centered-dialog">
            <v-container fill-height>
                <v-layout column justify-center align-center>
                    <v-progress-circular
                        indeterminate
                        :size="70"
                        :width="7"
                        :color="progressColor"
                    />
                    <h1 v-if="message != null">{{ message }}</h1>
                </v-layout>
            </v-container>
        </v-dialog>
    </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Loading',
    data() {
        return {};
    },
    props: {
        value: { type: Boolean, default: false },
        message: { type: String, default: null },
        progressColor: String,
        insideContainer: Boolean,
    },
    computed: {
        ...mapGetters([
            'color',
        ]),
    },
    mounted() {
        this.useColor = this.progressColor || this.color;
    }
};
</script>

<style>
.dialog.centered-dialog,
.v-dialog.centered-dialog {
    background: #282828aa;
    box-shadow: none;
    border-radius: 6px;
    width: auto;
    color: whitesmoke;
    z-index: 40;
}
</style>
