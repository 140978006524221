import api from '@/api';

export const getFileIds = (files, fileType) =>
    files.filter(file => file.source.fileType === fileType).map(file => file.id);

export const loadFilesBySource = async (store, collectionName, collectionId, fileType, isSignedUrl) => {
    const filesObj = await api.get(
        store,
        `file/source?collectionName=${encodeURI(collectionName)}&collectionId=${encodeURI(
            collectionId,
        )}&fileType=${encodeURI(fileType)}&isSignedUrl=${isSignedUrl || ''}`,
    );
    console.log('loadFilesBySource', filesObj.data);
    return filesObj.data;
};

export const loadFile = async (store, fileId, isSignedUrl) => {
    console.log('loadFile 1')
    const fileObjResponse = await api.get(store, `file/${fileId}?isSignedUrl=${isSignedUrl || ''}`);
    console.log('loadFile', fileObjResponse)
    return fileObjResponse.data;
};
