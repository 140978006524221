import api from '../api';

export default {
    state: {},
    mutations: {},
    actions: {
        async connectQBCompany(context) {
            try {
                const data = await api.post(context, 'qb/connectqb', {});
                return data.data.redirectTo;
            } catch (e) {
                return `?errmsg=Error Retrieving Company Data`;
            }
        },
        async completeQBCompany(context, inputData) {
            try {
                const result = await api.post(context, 'qb/completeqb', inputData);
                return result.data.companyId;
            } catch (e) {
                return 'complete failed';
            }
        },
    },
    getters: {},
};
