import Vuetify from 'vuetify';

import api from '../api';
import router from '../router';

export default {
    state: { user: null, sid: null, color: '#26c6da', dark: null },
    mutations: {
        storeUser(state, userData) {
            state.user = userData;
        },
        storeSid(state, sid) {
            state.sid = sid;
        },
        color(state, color) {
            state.color = color;
        },
        dark(state, dark) {
            state.dark = dark;
        },
    },
    actions: {
        async setStorageData(context, data) {
            const { user, sid } = data;
            context.commit('storeUser', user);
            context.commit('storeSid', sid);

        },
        async loginUser(context, userData) {
            try {
                const data = await api.post(context, 'auth/login', userData);
                if (data.success === false) {
                    throw new Error('Error Logging In');
                }
                if (data.data) {
                    const { sid, user } = data.data;
                    context.commit('storeUser', user);
                    context.commit('storeSid', sid);
                }
                return data.data;
            } catch (e) {
                throw e;
            }
        },
        async logoutUser(context) {
            try {
                api.post(context, 'auth/logout', {});
                context.commit('storeUser', null);
                context.commit('storeSid', null);
                router.push('/login');
            } catch (e) {
                throw e;
            }
        },
        async signupUser(context, userData) {
            try {
                const data = await api.post(context, 'auth/signup', userData);
                if (data.success === false) {
                    throw new Error('Error Signing up');
                }
                if (data.data) {
                    const { sid, user } = data.data;
                    context.commit('storeUser', user);
                    context.commit('storeSid', sid);
                }
                return data.data;
            } catch (e) {
                throw e;
            }
        },
        setColor(context, newColor) {
            context.commit('color', newColor);
        },
        setDark(context, newDark) {
            context.commit('dark', newDark);
        },
        selectColor(context, lightColor, darkColor) {
            const dark = context.getters.dark;

            return dark ? darkColor : lightColor;
        }
    },
    getters: {
        user(state) {
            return state.user;
        },
        isAuthenticated(state) {
            return state.user !== null;
        },
        sid(state) {
            return state.sid;
        },
        color(state) {
            return state.color;
        },
        dark(state) {
            return state.dark;
        },
    },
};
