<template>
    <fragment>
        <v-layout class="d-flex flex-column">
            <v-snackbar
                :value="isShowError"
                :timeout="10000"
                :bottom="true"
                color="error"
                transition="expand"
                :style="snackbarStyle"
            >
                {{ errorMessage }}
                <template v-slot:action="{ attrs }">
                    <btn label="Copy Error" :clipboardValue="errorMessage" preset="clipboard" />
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="
                            () => {
                                $store.dispatch('setError');
                            }
                        "
                    >
                        <v-icon text v-bind="$attrs">clear</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
            <v-snackbar :value="isShowInfo" :timeout="10000" :bottom="true" color="success" :style="snackbarStyle">
                {{ infoMessage }}
                <template v-slot:action="{ attrs }">
                    <btn label="Copy Info" :clipboardValue="infoMessage" preset="clipboard" />
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="
                            () => {
                                $store.dispatch('setInfo');
                            }
                        "
                    >
                        <v-icon text v-bind="$attrs">clear</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>

            <!--            <v-card :class="classExtra ? classExtra : 'mx-auto rounded-lg mt-3-lg mt-3'" outlined tile xs4>-->

            <page-header style="z-index: 20" class="d-flex flex-nowrap">
                <v-icon class="mr-4" x-large>{{ icon }}</v-icon>
                <span class="title font-weight-light" style="white-space: nowrap">{{ title }}</span>
                <div class="ml-5" />
                <slot name="header" class="d-flex" />
            </page-header>

            <div class="">
                <slot />
            </div>

            <page-footer v-if="showFooter">
                <slot name="actions" />
            </page-footer>
        </v-layout>
        <Loading v-if="isShowLoading" :value="isShowLoading" :message="loadingMessage" />
    </fragment>
</template>

<script>
import { mapGetters } from 'vuex';

import Loading from './Loading';
import PageHeader from '@/components/common/PageHeader';

export default {
    name: 'Page',
    components: { Loading, PageHeader },
    props: {
        title: String,
        icon: String,
        showFooter: Boolean,
    },
    data() {
        return {
            snackbarStyle: 'z-index: 60;',
        };
    },
    methods: {
        isBadBrowser() {
            // https://www.npmjs.com/package/vue-browser-detect-plugin
            if (
                this.$browserDetect.isIE ||
                this.$browserDetect.isChrome ||
                this.$browserDetect.isEdge ||
                this.$browserDetect.isSafari ||
                this.$browserDetect.isFirefox ||
                this.$browserDetect.isChromeIOS ||
                this.$browserDetect.isIOS
            ) {
                console.log('Browser Detected', this.$browserDetect);
                return false;
            }

            return true;
        },
        setMenu(sid) {
            this.sid = sid;
        },
    },
    computed: {
        ...mapGetters([
            'isShowLoading',
            'loadingMessage',
            'isShowError',
            'errorMessage',
            'isShowInfo',
            'infoMessage',
            'classExtra',
            'color',
        ]),
    },
    mounted() {
        if (this.isBadBrowser()) {
            //console.log('currentroute ',this.$router.currentRoute )
            if (this.$router.currentRoute.path !== '/badbrowser') {
                this.$router.push({ path: '/badbrowser' });
                return;
            }
        }
        this.$store.dispatch('setLoading');
        this.$store.dispatch('setError');
        this.$store.dispatch('setInfo');
    },
};
</script>
