<template>
    <v-checkbox v-bind="$attrs" v-model="localValue" xclass="px-2 py-0 ma-0">
        <template v-slot:label>
            <div class="text-caption">{{ label }}</div>
        </template>
    </v-checkbox>
</template>

<script>
export default {
    props: {
        value: null,
        label: null,
    },
    data() {
        return {
            localValue: '',
        };
    },
    updated() {
        this.localValue = this.value;
    },
    mounted() {
        this.localValue = this.value;
    },
    watch: {
        localValue() {
            this.$emit('input', this.localValue);
        },
    },
};
</script>
