<template>
    <read-only-field v-if="readOnly" v-bind="$attrs" :value="`${format(value)}`" />
    <text-field v-else v-bind="$attrs" ref="numeric" :placeholder="placeholder" :disabled="disabled" v-model="amount" type="tel" :prefix="currencySymbol" @blur="onBlurHandler" @focus="onFocusHandler" @change="onChangeHandler" />
</template>

<script>
import accounting from 'accounting-js';
export default {
    name: 'CurrencyField',
    props: {
        currency: { type: String, default: '' },
        max: { type: Number, default: Number.MAX_SAFE_INTEGER || 9007199254740991 },
        min: { type: Number, default: Number.MIN_SAFE_INTEGER || -9007199254740991 },
        minus: { type: Boolean, default: false },
        placeholder: { type: String, default: '' },
        emptyValue: { type: [Number, String], default: 0 },
        precision: { type: Number, default: 2 },
        separator: { type: String, default: ',' },
        thousandSeparator: { default: undefined, type: String },
        decimalSeparator: { default: undefined, type: String },
        value: { type: [Number, String], default: 0 },
        readOnly: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        currencySymbolPosition: { type: String, default: 'prefix' },
        currencySymbol: { type: String, default: '$' },
    },
    data: () => ({
        amount: '',
    }),
    computed: {
        amountNumber() {
            return this.unformat(this.amount);
        },
        valueNumber() {
            return this.unformat(this.value);
        },
        decimalSeparatorSymbol() {
            if (typeof this.decimalSeparator !== 'undefined') return this.decimalSeparator;
            if (this.separator === ',') return '.';
            return ',';
        },
        thousandSeparatorSymbol() {
            if (typeof this.thousandSeparator !== 'undefined') return this.thousandSeparator;
            if (this.separator === '.') return '.';
            if (this.separator === 'space') return ' ';
            return ',';
        },
        symbolPosition() {
            if (!this.currency) return '%v';
            return this.currencySymbolPosition === 'suffix' ? '%v %s' : '%s %v';
        },
    },
    watch: {
        valueNumber(newValue) {
            if (this.$refs.numeric !== document.activeElement) {
                this.amount = this.format(newValue);
            }
        },
        separator() {
            this.process(this.valueNumber);
            this.amount = this.format(this.valueNumber);
        },
        currency() {
            this.process(this.valueNumber);
            this.amount = this.format(this.valueNumber);
        },
        precision() {
            this.process(this.valueNumber);
            this.amount = this.format(this.valueNumber);
        },
    },
    methods: {
        onChangeHandler(e) {
            this.$emit('input', this.amountNumber);
            this.$emit('change', this.amountNumber);
        },
        onBlurHandler(e) {
            // this.process(this.amountNumber);
            this.$emit('blur', e);
            this.$emit('input', this.amountNumber);
            this.amount = this.format(this.valueNumber);
        },
        onFocusHandler(e) {
            this.$emit('focus', e);

            this.amount = accounting.formatMoney(this.valueNumber, {
                symbol: '',
                format: '%v',
                thousand: '',
                decimal: this.decimalSeparatorSymbol,
                precision: Number(this.precision),
            });

            const inputEl = this.$refs.numeric.$el.querySelector('input');
            setTimeout(() => {
                inputEl && inputEl.select();
            }, 100);
        },
        process(value) {
            if (value >= this.max) this.update(this.max);
            if (value <= this.min) this.update(this.min);
            if (value > this.min && value < this.max) this.update(value);
            if (!this.minus && value < 0) this.min >= 0 ? this.update(this.min) : this.update(0);
        },
        update(val) {
            if (this.amount !== val) {
                this.amount = this.format(val);
            }
            this.$emit('input', this.amount);
        },
        format(value) {
            return accounting.formatMoney(value, {
                symbol: this.currency,
                format: this.symbolPosition,
                precision: Number(this.precision),
                decimal: this.decimalSeparatorSymbol,
                thousand: this.thousandSeparatorSymbol,
            });
        },
        unformat(value) {
            const toUnformat = value === '' ? this.emptyValue : value;
            const returnValue = accounting.unformat(toUnformat, this.decimalSeparatorSymbol);
            return returnValue;
        },
    },
    mounted() {
        //this.process(this.valueNumber);
        this.amount = this.format(this.valueNumber); // ab this WAS commented adn the release payments form wasn't updating on load
    },
};
</script>
