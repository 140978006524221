export default {
    state: { filters: [] },
    mutations: {
        doCloseFilterDialog(state, data) {
            const newFilters = [...state.filters];
            newFilters[data.idx] = { ...newFilters[data.idx], dialog: false };
            state.filters = [...newFilters];
        },
        doAddFilter(state, data) {
            state.filters = [...state.filters, data.filter];
        },
        doRemoveFilter(state, data) {
            const newFilters = [...state.filters];
            state.filters = newFilters.filter((filter, idx) => idx !== data.idx);
        },
    },
    actions: {
        closeFilterDialog(context, data) {
            context.commit('doCloseFilterDialog', data);
        },
        addFilter(context, data) {
            context.commit('doAddFilter', data);
        },
        removeFilter(context, data) {
            context.commit('doRemoveFilter', data);
        },
    },
    getters: {
        filters(state) {
            return state.filters;
        },
    },
};
