<template>
    <div v-if="isLoaded" fluid :class="micro ? 'my-3 pa-0' : isRemovePadding ? 'mx-7 my-0 constrain-width' : 'ma-7 constrain-width'">
        <div v-if="adhoc">
            <v-row class="pt-6 pb-5 pl-5 title">
                {{ title }}
            </v-row>
            <v-row :class="getClass">
                <slot />
            </v-row>
        </div>
        <div v-else>
            <fragment v-for="(oneFormSchema, schemaIndex) in formSchemas" :key="`field group ${JSON.stringify(oneFormSchema)}`">
                <v-row v-if="oneFormSchema.title" class="pt-6 pb-5 pl-5 title">
                    {{ oneFormSchema.title }}
                </v-row>
                <v-row v-if="formValue && oneFormSchema.schema" :class="getClass">
                    <v-row :col="col" v-if="oneFormSchema.fieldNames">
                        <fragment
                            v-for="(oneFieldName) in oneFormSchema.fieldNames"
                            :key="`field ${oneFieldName} ${schemaIndex}`"
                        >
                            <!--                        <fragment-->
                            <!--                            v-for="(oneFieldName, index) in oneFormSchema.fieldNames"-->
                            <!--                            :key="`${oneFieldName} ${index} field fragment`"-->
                            <!--                        >-->
                            <v-col
                                v-if="oneFormSchema.schema[oneFieldName] && oneFormSchema.schema[oneFieldName].cols"
                                :cols="oneFormSchema.schema[oneFieldName].cols"
                                :class="micro ? 'py-0 d-flex' : 'py-0'"
                            >
                                <span style="color: #666; font-size: 14px" class="pr-2">{{
                                    oneFormSchema.schema[oneFieldName].label
                                }}</span>
                                <component
                                    :read-only="oneFormSchema.schema[oneFieldName].readOnly || micro"
                                    v-bind="oneFormSchema.schema[oneFieldName]"
                                    :id="oneFieldName"
                                    :is="oneFormSchema.schema[oneFieldName].component"
                                    :value="formValue[oneFieldName]"
                                    label=""
                                    :class="oneFormSchema.schema[oneFieldName].className"
                                    dense
                                    :ref="oneFieldName"
                                    :micro="micro"
                                    :formData="formValue"
                                    :key="getKey(oneFormSchema, oneFieldName)"
                                    @input="
                                        (val) => {
                                            onFieldInput(oneFieldName, val);
                                        }
                                    "
                                />
                            </v-col>
                            <v-col
                                v-if="
                                    oneFormSchema.schema[oneFieldName] && oneFormSchema.schema[oneFieldName].blankCols
                                "
                                :cols="oneFormSchema.schema[oneFieldName].blankCols"
                                :class="micro ? 'py-0 d-flex' : 'py-0'"
                            >
                                <blank-field />
                            </v-col>
                        </fragment>
                    </v-row>
                </v-row>
                <v-row v-else :class="hideBorder || micro ? 'form-no-border' : 'rounded-border pa-6'">
                    <slot />
                </v-row>
            </fragment>
        </div>
    </div>
</template>
<script>
import api from '@/api';

import cleanUtil from '@/../common/utils/clean';
import objectUtil from '../../../common/utils/object';

export default {
    name: 'SmartForm',
    props: {
        formType: String,
        value: Object,
        schema: [Object, Array],
        dataSets: Object,
        formCode: String,
        isHistory: [String, Boolean],
        hideBorder: Boolean,
        hideBackground: Boolean,
        micro: Boolean,
        formMode: String,
        adhoc: Boolean,
        title: String,
        isRemovePadding: Boolean,
    },
    data() {
        return {
            formValue: null,
            formSchemas: null,
            col: {},
            useDataSets: null,
            isLoaded: false,
            allMonitoredFields: {},
        };
    },
    methods: {
        initialiseOneField(fieldName, schemaCount) {
            console.log('initialising one field', fieldName, schemaCount, this.formValue[fieldName]);
            if (cleanUtil.validate.isString(this.formSchemas[schemaCount].schema[fieldName])) {
                this.formSchemas[schemaCount].schema[fieldName] = {
                    component: this.formSchemas[schemaCount].schema[fieldName],
                };
            } else {
                const oneField = this.formSchemas[schemaCount].schema[fieldName];
                // inject datasets onto field
                if (this.useDataSets && oneField.dataSets) {
                    Object.keys(oneField.dataSets).map((oneDataSetName) => {
                        if (this.useDataSets[oneField.dataSets[oneDataSetName]]) {
                            oneField[oneDataSetName] = this.useDataSets[oneField.dataSets[oneDataSetName]];
                        }
                    });
                }
                this.formSchemas[schemaCount].schema[fieldName] = oneField;
            }
        },
        initialiseFields() {
            /*
            eg taxStatus: {
                    component: 'SelectField',
                    label: 'Tax Status',
                    dataSets: { items: 'taxStatuses' },
                },
             */

            console.log('initialiseFields 1', this.formValue);

            this.formSchemas.map((oneFormSchema, schemaCount) => {
                const formKeys = Object.keys(oneFormSchema.schema);

                oneFormSchema.fieldNames = formKeys;

                formKeys.map((key) => {
                    if (oneFormSchema.schema[key].component !== 'blank-field' && this.formValue[key] === undefined) {
                        const fieldSchema = oneFormSchema.schema[key];
                        const { autocomplete, component } = fieldSchema;

                        const defaultValue =
                            component === 'currency-field'
                                ? 0
                                : component === 'color-field'
                                ? '#707070FF'
                                : autocomplete
                                ? null
                                : '';
                        this.formValue[key] = fieldSchema.defaultValue || defaultValue;
                    }
                    this.initialiseOneField(key, schemaCount);
                });
            });
            console.log('initialiseFields 2', this.formValue);
        },
        async init() {
            if (this.adhoc) {
            } else {
                this.useDataSets = this.dataSets || {};

                const value =
                    this.formCode && this.isHistory
                        ? await this.$store.dispatch('getFormData', this.formCode)
                        : this.value;

                this.formSchemas = api.getFormSchemas(this.schema);
                this.formValue = value || {};
                this.col = { cols: 12, sm: 6, md: 3 };

                this.initialiseFields();

                await this.persistFormValue();
            }
        },
        async persistFormValue() {
            if (this.formCode) {
                await this.$store.dispatch('setFormData', {
                    formCode: this.formCode,
                    formValue: this.formValue,
                });
            }
        },
        doOnInput(key, val) {
            console.log('smart-form doOnInput', { key, val })
            this.formValue = { ...this.formValue, [key]: val };
        },
        doOnChange(key, val) {
            console.log(
                'doOnChange',
                JSON.stringify(this.formValue.companyLink),
                JSON.stringify(this.formValue.propertyLink),
            );
            // this.formValue = { ...this.formValue, [key]: val };
            // console.log('doOnChange', JSON.stringify(this.formValue, null, 2));
            this.$emit('input', this.formValue);
            // this.$emit('change', this.formValue);
        },
        getFormData(schema, fieldName) {
            const monitorFields = objectUtil.getObjectFieldByString(`${fieldName}.monitorFields`, schema.schema);
            console.log('getFormData', fieldName, monitorFields);
            if (monitorFields) {
                const monitoredFields = monitorFields.reduce((accum, oneMonitoredField) => {
                    return {
                        ...accum,
                        [oneMonitoredField]: this.formValue[oneMonitoredField],
                    };
                }, {});

                const savedMonitoredFields = this.allMonitoredFields[fieldName];

                if (!savedMonitoredFields || JSON.stringify(monitoredFields) !== JSON.stringify(savedMonitoredFields)) {
                    this.allMonitoredFields[fieldName] = monitoredFields;
                }

                return this.allMonitoredFields[fieldName];
            }

            return '';
        },
        getKey(schema, fieldName) {
            return `field ${fieldName} ${JSON.stringify(this.getFormData(schema, fieldName))}`;
        },
        onFieldInput(fieldName, val) {
            // console.log('onFieldInput', fieldName, val, JSON.stringify(val) === JSON.stringify(this.formValue[fieldName]));
            if (JSON.stringify(val) !== JSON.stringify(this.formValue[fieldName])) {
                // console.log('onFieldInput UPDATED!!!!!', fieldName, val, JSON.stringify(val) === JSON.stringify(this.formValue[fieldName]));
                this.formValue = { ...this.formValue, [fieldName]: val };
                this.$emit('input', this.formValue);
            }
        },
    },
    watch: {
        formValue() {
            console.log('formValue updated', this.formValue);
        },
    },
    computed: {
        getClass() {
            const background = this.hideBackground ? 'hide-background' : 'form-background rounded-border';
            return this.hideBorder || this.micro
                ? `form-no-border ${background} pa-6`
                : `rounded-border ${background} py-6`;
        },
    },
    updated() {
        console.log('smartformupdated', JSON.stringify(this.formValue, null, 2));
    },
    async mounted() {
        console.log('smartformmounted start');
        await this.init();
        console.log('smartformmounted end', this.formValue.customerLink);
        this.isLoaded = true;
    },
};
</script>
<style scoped>
.form-background {
    background-color: #80808020;
    border: 0;
    margin: 7px;
}
.hide-background {
    border: 0;
}
</style>
