<template>
    <!--    <fragment>-->
    <!--        <debug :value="localValue" />-->
    <autocomplete-field
        v-if="autocomplete"
        :value="value"
        :label="label"
        :return-object="returnObject"
        :hide-no-data="hideNoData"
        :all-items-text="allItemsText"
        :mask="mask"
        :mask-options="maskOptions"
        :form-data="formData"
        :autocomplete="autocomplete"
        @change="(val) => { logvals('changed text auto', val); $emit('input', val);  $emit('change', val);  }"
        v-bind="$attrs"
        v-on="$listeners"
        :read-only="readOnly"
    />
    <read-only-field v-else-if="readOnly" v-bind="$attrs" v-on="$listeners" :value="value" />
    <v-text-field
        v-else-if="localValue !== null"
        :label="label"
        v-model="localValue"
        v-mask="useMask"
        ref="fieldComponent"
        @click="(val) => doOnGeneric('click', 'onClick', val)"
        @change="(val) => doOnGeneric('change', 'onChange', val)"
        @focus="(val) => doOnGeneric('focus', 'onFocus', val)"
        @blur="(val) => doOnGeneric('blur', 'onBlur', val)"
        @input="(val) => doOnGeneric('input', 'onInput', val)"
        v-bind="$attrs"
    >
        <template v-for="(index, name) in $slots" v-slot:[name]>
            <slot :name="name" />
        </template>
    </v-text-field>
    <!--    </fragment>-->
</template>

<script>
import { runMask } from '@/../common/utils/mask';

import AutocompleteField from '@/components/form/fields/AutocompleteField';

export default {
    props: {
        value: null,
        label: null,
        autocomplete: null,
        returnObject: null,
        hideNoData: Boolean,
        allItemsText: String,
        readOnly: Boolean,
        mask: [String, Function],
        maskOptions: null,
        formData: null,
    },
    components: { AutocompleteField },
    data() {
        return {
            localValue: null,
            localText: '',
            loading: false,
            items: [],
            searchValue: null,
            useMask: null,
        };
    },
    methods: {
        runMask,
        doOnGeneric(event, attrEvent, val) {
            if (event !== 'input' || val !== this.value) {
                if (this.$attrs[attrEvent]) {
                    this.$attrs[attrEvent](val);
                }
                this.$emit(event, val);
            }
        },
        logvals(...params) {
            console.log('TEXTFIELD-LOGVALS ------ ', ...params);
        }
    },
    computed: {
        getTextValue() {
            if (!this.autocomplete) return this.value;

            const itemValue = this.items.find((oneItem) => oneItem === this.value);
            if (typeof itemValue !== 'undefined') return itemValue;

            const itemObject = this.items.find((oneItem) => oneItem.id === this.value.id);
            return itemObject ? itemObject.text : 'no value';
        },
    },
    updated() {
        // console.log('textfield updated');
        this.localValue = this.value;
    },
    async mounted() {
        // console.log('textfield mounted');
        this.localValue = this.value;
        this.useMask = this.maskOptions ? this.runMask(this.maskOptions) : this.mask;
    },
};
</script>
