<template>
    <v-row :col="col" v-if="fieldNames">
        <fragment v-for="(oneFieldName, index) in fieldNames" :key="`${oneFieldName} ${index}`">
            <v-col v-if="schema[oneFieldName].cols" :cols="schema[oneFieldName].cols" :class="micro ? 'py-0 d-flex' : 'py-0'">
                <span style="color: #666; font-size: 14px" class="pr-2">{{ schema[oneFieldName].label }}</span>
                <component
                    :read-only="schema[oneFieldName].readOnly || micro"
                    v-bind="schema[oneFieldName]"
                    :id="oneFieldName"
                    :is="schema[oneFieldName].component"
                    v-model="useValue[oneFieldName]"
                    label=""
                    @input="(e) => doOnInput(oneFieldName, e)"
                    @change="(e) => doOnChange(oneFieldName, e)"
                    @focus="() => doOnFocus(oneFieldName)"
                    @blur="() => doOnBlur(oneFieldName)"
                    :class="schema[oneFieldName].className"
                    dense
                    :ref="oneFieldName"
                    :micro="micro"
                    :formData="getFormData(oneFieldName)"
                    :key="getKey(oneFieldName)"
                />
                <!-- <debug :value="useValue[oneFieldName]" /> -->
                <!-- <v-subheader class="orange&#45;&#45;text darken-2" v-if="true || warningText">sub text{{warningText}}</v-subheader> -->
            </v-col>
            <v-col
                v-if="schema[oneFieldName].blankCols"
                :cols="schema[oneFieldName].blankCols"
                :class="micro ? 'py-0 d-flex' : 'py-0'"
            >
                <component :is="`blank-field`" />
            </v-col>
        </fragment>
    </v-row>
</template>
<script>
import objectUtil from '@/../common/utils/object';

export default {
    name: 'FormFields',
    props: {
        id: {
            type: String,
            default: 'default-form',
        },
        row: {
            type: [Object],
        },
        col: {
            type: [Object, Number, String],
        },
        flex: {
            type: [Object, Number, String],
        },
        value: {
            type: [Object, Array],
            default: () => null,
        },
        schema: {
            type: [Object, Array],
            default: () => ({}),
        },
        readOnly: Boolean,
        micro: Boolean,
        onInput: Function,
    },
    data() {
        return {
            fieldNames: null,
            isLoaded: false,
            warningText: null,
            useValue: null,
            focusedField: null,
            allMonitoredFields: {},
            emptyObject: {},
        };
    },
    methods: {
        doOnFocus(key) {
            this.focusedField = key;
            this.$emit('focus', key);
        },
        doOnBlur(key) {
            if (this.focusedField === key) {
                this.focusedField = null;
            }
            this.$emit('blur', key);
        },
        doOnInput(key, val) {
            this.useValue[key] = val;
        },
        doOnChange() {
            // console.log('formFields doOnChange', key, this.useValue);
            this.$emit('input', this.useValue);
            this.$emit('change', this.useValue);
        },
        useKey(fieldId) {
            return `${JSON.stringify(this.useValue)} ${fieldId}`;
        },
        getFormData(fieldName) {
            const monitorFields = objectUtil.getObjectFieldByString(`${fieldName}.monitorFields`, this.schema);

            if (monitorFields) {
                const monitoredFields = monitorFields.reduce((accum, oneMonitoredField) => {
                    return {
                        ...accum,
                        [oneMonitoredField]: this.useValue[oneMonitoredField],
                    };
                }, {});

                const savedMonitoredFields = this.allMonitoredFields[fieldName];

                if (!savedMonitoredFields || JSON.stringify(monitoredFields) !== JSON.stringify(savedMonitoredFields)) {
                    this.allMonitoredFields[fieldName] = monitoredFields;
                }

                return this.allMonitoredFields[fieldName];
            }
        },
        getKey(fieldName) {
            return JSON.stringify(this.getFormData(fieldName));
        },
        init() {
            this.useValue = { ...this.value };
            this.fieldNames = Object.keys(this.schema);
        },
    },
    updated() {
        // console.log('formfields updated', JSON.stringify(this.schema).length, this.schema);
    },
    created() {
        this.init();
        // console.log('formfields created', JSON.stringify(this.schema).length, this.schema);
    },
};
</script>
