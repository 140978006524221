<template>
    <v-container :class="`ma-2 ${useClass || ''}`">
        <v-row class="d-flex">
            <div class="d-flex" v-for="item in useLeftItems" :key="item.name">
                <toolbar-item v-if="!validButtonIds || validButtonIds.includes(item.id)" v-bind="item" />
            </div>
            <div class="ma-0">
                <slot class="d-flex" />
                <slot name="leftItems" class="d-flex" />
            </div>
            <v-spacer />
            <div class="d-flex" v-for="item in useRightItems" :key="item.name">
                <toolbar-item v-if="!validButtonIds || validButtonIds.includes(item.id)" v-bind="item" />
            </div>
            <div class="ma-0">
                <slot name="rightItems" />
            </div>
        </v-row>
        <v-row class="d-flex">
            <v-spacer />
            <div class="d-flex" v-for="item in useCenterItems" :key="item.name">
                <toolbar-item v-if="!validButtonIds || validButtonIds.includes(item.id)" v-bind="item" />
            </div>
            <div class="ma-0">
                <slot name="centerItems" />
            </div>
            <v-spacer />
        </v-row>
    </v-container>
</template>

<!--
usage

        <toolbar>
            <btn @click="logForm">Left</btn>
            <template v-slot:right>
                <btn @click="logForm">Right</btn>
            </template>
            <template v-slot:center>
                <btn @click="logForm">Center</btn>
            </template>
        </toolbar>

OR

        <toolbar
            :left-items="[
                { label: 'Click Something', name: 'something', onClick: logForm, className: 'primary' },
                {
                    label: 'Delete Something',
                    name: 'deletesomething',
                    onClick: fakeDelete,
                    className: 'warning',
                    confirm: 'Are you sure you want to delete?',
                },
                {
                    label: 'Delete Another',
                    name: 'another',
                    onClick: fakeDelete,
                    className: 'error',
                    confirm: 'Are you sure you want to delete another?',
                },
            ]"
            :right-items="[
                { label: 'Done', name: 'done', onClick: logForm, className: 'primary' },

            ]"
        />

-->

<script>
import ToolbarItem from '@/components/form/buttons/ToolbarItem';

export default {
    name: 'Toolbar',
    inheritAttrs: false,
    components: { ToolbarItem },
    props: {
        leftItems: null,
        centerItems: null,
        rightItems: null,
        useClass: null,
        validButtonIds: null,
        buttonFunctions: null,
        buttonFunctionData: null,
    },
    data() {
        return {
            useLeftItems: [],
            useCenterItems: [],
            useRightItems: [],
        };
    },
    methods: {
        clickButton(clickFunctionName) {
            return async () => {
                console.log(
                    'src/components/form/buttons/Toolbar.vue clickButton 1',
                    clickFunctionName,
                    Object.keys(this.buttonFunctions),
                );
                await this.buttonFunctions[clickFunctionName](this.formModel);
            };
        },
        getButtons(buttons) {
            if (!buttons) return [];

            if (!this.buttonFunctions) {
                return buttons;
            }

            console.log('src/components/form/buttons/Toolbar.vue getButtons 1', { buttons });
            return buttons
                .filter((oneButton) => oneButton)
                .map((oneButton) => ({
                    ...oneButton,
                    onClick: oneButton.clickFunctionName
                        ? this.clickButton(oneButton.clickFunctionName)
                        : oneButton.onClick,
                    clickFunctionName: undefined,
                }));
        },
    },
    mounted() {
        this.useLeftItems = this.getButtons(this.leftItems);
        this.useCenterItems = this.getButtons(this.centerItems);
        this.useRightItems = this.getButtons(this.rightItems);
    },
};
</script>

<style scoped></style>
